export const PepModalContent = () => {
    return (
        <div style={{ marginTop: '50px' }}>
            Politicky exponovanou osobou se rozumí:
            <ol style={{ listStyleType: 'none' }}>
                <li className="orderedListItem">
                    1. fyzická osoba, která je ve významné veřejné funkci s celostátním nebo regionálním významem, jako
                    je zejména hlava státu, předseda vlády, vedoucí ústředního orgánu státní správy a jeho zástupce
                    (náměstek, státní tajemník), člen parlamentu, člen řídícího orgánu politické strany, vedoucí
                    představitel územní samosprávy, soudce nejvyššího soudu, ústavního soudu nebo jiného nejvyššího
                    justičního orgánu, proti jehož rozhodnutí obecně až na výjimky nelze použít opravné prostředky, člen
                    bankovní rady centrální banky, vysoký důstojník ozbrojených sil nebo sboru, člen nebo zástupce
                    člena, je-li jím právnická osoba, statutárního orgánu obchodní korporace ovládané státem,
                    velvyslanec nebo vedoucí diplomatické mise, anebo fyzická osoba, která obdobnou funkci vykonává nebo
                    vykonávala v jiném státě, v orgánu Evropské unie anebo v mezinárodní organizaci.
                </li>
                <li className="orderedListItem">
                    2. fyzická osoba, která je:  1. osobou blízkou osobě uvedené v bodě 1.,  2. společníkem nebo
                    skutečným majitelem stejné právnické osoby, popřípadě svěřeneckého fondu nebo jiného právního
                    uspořádání bez právní osobnosti, jako osoba uvedená v bodě 1., nebo je o ní povinné osobě známo, že
                    je v jakémkoliv jiném blízkém podnikatelském vztahu s osobou uvedenou v bodě 1., nebo  3. skutečným
                    majitelem právnické osoby, popřípadě svěřeneckého fondu nebo jiného právního uspořádání bez právní
                    osobnosti, o kterých je povinné osobě známo, že byly vytvořeny ve prospěch osoby uvedené v bodě 1.
                </li>
            </ol>
        </div>
    );
};
