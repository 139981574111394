import { Fragment, ReactNode } from 'react';
import { Logo } from '../../Logo/Logo';
import Footer from './Footer';
import './Layout.styles.css';

type Props = {
    children: ReactNode;
    headline?: ReactNode;
    description?: string;
    headerContent?: ReactNode;
    hideFooter?: boolean;
    footerContent?: ReactNode;
};

export const Layout = ({
    children,
    headline,
    description,
    headerContent,
    hideFooter = false,
    footerContent,
}: Props) => {
    return (
        <Fragment>
            <div className="layout">
                <header className="navbar" data-test="header">
                    <Logo />
                    {headerContent && <div className="headerContent">{headerContent}</div>}
                </header>
                <main className="main" data-test="main">
                    <div className="container">
                        {headline && (
                            <div className="headline" data-test="headline">
                                {headline}
                            </div>
                        )}
                        {description && (
                            <p className="description mb-10" data-test="description">
                                {description}
                            </p>
                        )}
                        {children}
                    </div>
                </main>
                {!hideFooter && <Footer>{footerContent}</Footer>}
            </div>
        </Fragment>
    );
};
