import { useEffect, useState } from 'react';
import { ROUTES } from '../routes/routes';
import { constants } from '../utils/constants';
import { useSearchParams } from 'react-router-dom';
import axios from 'axios';
import { getBaseUrl, parseJson } from '../utils';

const baseUrl = window.location.origin;
const targetUrl = baseUrl + ROUTES.bankIdProcessing;
const replaceTargetUrl = '{#returnUrl}';
const replaceTargetApprover = '{#approverId}';

export const useBankId = () => {
    const [bankIdUrl, setBankIdUrl] = useState<string>('');
    const [searchParams] = useSearchParams();

    const processId = searchParams.get(constants.PROCESS_ID_QUERY_PARAM);

    useEffect(() => {
        axios
            .post(
                `${getBaseUrl()}/api/client-aml-identification/get-bankId-identification-init-data`,
                {},
                {
                    timeout: 10000,
                }
            )
            .then((response) => {
                const initData = parseJson(response.data.BankIdIdentificationInitData || {});
                const url = initData.ConfigurationData.BankIdApprovalUrl.replace(replaceTargetUrl, targetUrl).replace(
                    replaceTargetApprover,
                    processId
                );
                setBankIdUrl(url);
            })
            .catch((error: any) => {
                console.log('err: ', error);
            });
    }, [processId]);

    return { bankIdUrl, processId };
};
