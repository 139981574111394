import { useEffect, useState } from 'react';
import { Modal } from './Modal';

export const useModal = () => {
    const [isOpen, setIsOpen] = useState(false);

    useEffect(() => {
        if (isOpen) {
            document.getElementById('bodyId')?.classList.add('stop-scrolling');
        } else {
            document.getElementById('bodyId')?.classList.remove('stop-scrolling');
        }
    }, [isOpen]);
    return {
        isOpen,
        setIsOpen,
        Modal,
    };
};
