import { Dispatch, ReactNode, SetStateAction } from 'react';
import './Modal.styles.css';
import { Close } from '..';

type HeadingSize = 'm' | 'xl';

type Props = {
    setIsOpen: Dispatch<SetStateAction<boolean>>;
    heading: string;
    content: ReactNode;
    footer?: ReactNode;
    headingSize?: HeadingSize;
};

export const Modal = ({ setIsOpen, heading, content: Content, footer, headingSize = 'm' }: Props) => {
    const closeModal = () => setIsOpen(false);
    return (
        <>
            <div className="darkBG" onClick={closeModal} />
            <div className="modalCentered">
                <div className="modal">
                    <div className="modalHeader">
                        <h5 className={`heading ${headingSize === 'm' ? 'headingM' : 'headingXl'}`}>{heading}</h5>
                    </div>
                    <Close className="closeBtn" onClick={closeModal} />
                    <div className="modalContent">{Content}</div>
                    {footer && <div className="modalFooter">{footer}</div>}
                </div>
            </div>
        </>
    );
};
