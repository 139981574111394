import React, { ChangeEvent, FocusEvent, KeyboardEvent, forwardRef, useEffect, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import DatePicker from 'react-datepicker';
import cn from 'classnames';
import dayjs from 'dayjs';

import 'react-datepicker/dist/react-datepicker.css';
import './DateInput.styles.css';

import { getDataTest } from '../../utils';
import { FieldInputProps, FormikProps, FormikValues, useField } from 'formik';
import { constants } from '../../utils/constants';

require('dayjs/locale/es');

var customParseFormat = require('dayjs/plugin/customParseFormat');
dayjs.extend(customParseFormat);

export interface InputProps {}

interface DateInputProps extends InputProps {
    field: FieldInputProps<string | number>;
    form?: FormikProps<FormikValues>;
    id: string;
    name: string;
    label?: string;
    isCenter?: boolean;
    className?: string;
    placeholder?: string;
    helpText?: string;
    disabled?: boolean;
    value?: string;
    excludeDates?: Date[];
    minDate?: Date;
    maxDate?: Date;
    fieldClassName?: string;
    helpLocation?: 'label' | 'input';
    withoutHelperText?: boolean;
    autoFocus?: boolean;
    errorMessage?: string;
    onChange?: (e: ChangeEvent<HTMLInputElement>) => void;
}

const CustomDateInput = forwardRef((props, ref: any) => <input {...props} ref={ref} />);

const DateInput = ({
    label,
    className,
    form,
    isCenter = true,
    disabled = false,
    fieldClassName,
    helpText,
    helpLocation = 'input',
    withoutHelperText = false,
    errorMessage,
    minDate,
    maxDate,
    onChange,
    ...props
}: DateInputProps) => {
    const { t } = useTranslation();
    const ref = useRef<any>(null);

    const [field, meta] = useField(props);
    const { error, touched } = meta;

    const [selected, setSelected] = useState<string | number | null>(field?.value || null);

    const days = [
        t('general.days.su'),
        t('general.days.mo'),
        t('general.days.tu'),
        t('general.days.we'),
        t('general.days.th'),
        t('general.days.fr'),
        t('general.days.sa'),
    ];

    const months = [
        t('general.months.january'),
        t('general.months.february'),
        t('general.months.march'),
        t('general.months.april'),
        t('general.months.may'),
        t('general.months.june'),
        t('general.months.july'),
        t('general.months.august'),
        t('general.months.september'),
        t('general.months.october'),
        t('general.months.november'),
        t('general.months.december'),
    ];

    const locale: any = {
        localize: {
            day: (n: number) => days[n],
            month: (n: number) => months[n],
        },
        formatLong: {
            date: () => 'dd.MM.yyyy',
        },
    };

    const inErrorMessage = useMemo(
        () => (field?.name && form?.errors[field.name] ? form.errors[field.name]?.toString() : errorMessage),
        [field?.name, form?.errors, errorMessage]
    );

    const changeDate = (date: string | null) => {
        const parsedDate = dayjs(date);
        const isValid = parsedDate.isValid();

        const newEvent = {
            target: {
                name: field?.name,
                value: isValid ? parsedDate.format(constants.DATE_FORMAT) : null,
            },
        } as ChangeEvent<HTMLInputElement>;

        setSelected(newEvent.target.value);
        field?.onChange(newEvent);
        onChange && onChange(newEvent);
    };

    const handleKeyDown = (e: KeyboardEvent) => {
        const key = e.key.toLowerCase();
        if ((e.metaKey || e.ctrlKey) && ['a', 'c', 'v', 'x', 'tab'].includes(key)) {
            return;
        }
        if (e.shiftKey && ['home'].includes(key)) {
            return;
        }
        const v = ref.current.input.value;
        const valueByPoints = v?.split(/\./g) || [];
        const pressContentKey = ['0', '1', '2', '3', '4', '5', '6', '7', '8', '9'].includes(key);
        const pressFnKey = ['.', 'delete', 'tab', 'backspace', 'arrowleft', 'arrowright'].includes(key);
        if (!pressContentKey && !pressFnKey) {
            e.preventDefault();
        } else if (pressContentKey && ref.current.input.value.length >= 10) {
            e.preventDefault();
        } else if (key === '.' && valueByPoints.length > 2) {
            e.preventDefault();
        } else {
            // stisk korektní klávesy
            if (pressContentKey && ref.current.input.selectionStart === v.length) {
                if (
                    (valueByPoints.length === 1 && valueByPoints[0].length === 2) ||
                    (valueByPoints.length === 2 && valueByPoints[1].length === 2)
                ) {
                    ref.current.input.value = ref.current.input.value + '.';
                }
            }
        }
    };

    const handleBlur = (e: FocusEvent<HTMLInputElement>) => {
        const { value } = e.target;
        const parsedDate = dayjs(value, ['DD.MM.YYYY', 'D.MM.YYYY', 'D.M.YYYY'], 'es', true);
        const isValid = parsedDate.isValid();
        if (!isValid) {
            changeDate(null);
        } else {
            changeDate(parsedDate.format(constants.DATE_FORMAT));
        }
    };

    useEffect(() => {
        if (field?.value !== selected) {
            setSelected(field?.value);
        }
    }, [field?.value, selected]);

    return (
        <div className="inputWrap">
            <DatePicker
                {...props}
                ref={ref}
                closeOnScroll
                selected={selected ? new Date(selected) : null}
                isClearable
                disabled={disabled}
                placeholderText="dd. mm. yyyy"
                dateFormat="dd.MM.yyyy"
                locale={locale}
                calendarStartDay={1}
                showMonthDropdown
                showYearDropdown
                dropdownMode="select"
                customInput={<CustomDateInput data-test={getDataTest(props)} />}
                maxDate={maxDate}
                minDate={minDate}
                className={cn(
                    'inputStyles',
                    (!!inErrorMessage || (!!error && touched)) && 'hasError',
                    isCenter && 'text-center',
                    isCenter && 'justify-center'
                )}
                // className={cn(
                //     'inline-block w-full shrink-0 grow rounded-full border !bg-white px-4 [&:focus]:border-darkPurple',
                //     // size === 'medium' && 'h-[3rem] md:h-[3.25rem]',
                //     // size === 'small' && 'h-10',
                //     'inline-flex items-center justify-start',
                //     isCenter && 'text-center',
                //     isCenter && 'justify-center',
                //     // inErrorMessage ? 'border-red' : 'border-purple',
                //     disabled && 'pointer-events-none border-gray !text-gray',
                //     fieldClassName
                // )}
                onKeyDown={handleKeyDown}
                // onChangeRaw={(e: MouseEvent<HTMLElement>) => {
                //     const date = e.target.value;
                //     if (!disabled) {
                //         const parsedDate = dayjs(date, ['DD.MM.YYYY', 'D.MM.YYYY', 'D.M.YYYY'], 'es', true);
                //         const isValid = parsedDate.isValid();
                //         changeDate(isValid ? parsedDate.format(constants.DATE_FORMAT) : null);
                //     }
                // }}
                onChange={(date: any) => {
                    if (!disabled) {
                        if (date === null) {
                            changeDate(null);
                        } else {
                            changeDate(date);
                        }
                    }
                }}
                onBlur={handleBlur}
                portalId="date-picker-pportal"
                data-test={getDataTest(props, 'Input')}
            />
        </div>
    );
};

export default DateInput;
