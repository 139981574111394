import axios from 'axios';
import { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { getBaseUrl } from '../../../utils';
import { Layout } from '../../../components';
import { Loader } from '@simplea/shared-ui';
import { BankIdConfirmation } from '../BankIdConfirmation/BankIdConfirmation';
import './Processing.styles.css';
import { useTranslation } from 'react-i18next';
import { bankIdErrorMapper, errorLayoutProps } from './utils';
import { ProcessingErrors } from './ProcessingErrors';

export type IdentificationError = {
    isInvalid: boolean;
    isBankIdError: boolean;
    data: any[];
};

const errorInitialState = {
    isInvalid: false,
    isBankIdError: false,
    data: [],
};

export const Processing = () => {
    const [error, setError] = useState<IdentificationError>(errorInitialState);
    const [response, setResponse] = useState();
    const [isLoading, setIsLoading] = useState(true);

    const { search, hash } = useLocation();
    const { t } = useTranslation();

    const newHash = '?' + hash.substring(1);
    const params = new URLSearchParams(newHash);

    const accessToken = params.get('access_token');
    const tokenTypeText = params.get('token_type');
    const idTokenText = params.get('id_token');
    const stateText = params.get('state') || search.split('state=')[1];
    const expiresInText = params.get('expires_in');

    const isCustomError = error.data.length > 0;

    useEffect(() => {
        axios
            .post(
                `${getBaseUrl()}/api/client-aml-identification/prepare-identification-data-by-bank-id`,
                {
                    ipAddress: window.location.host,
                    accessToken,
                    tokenTypeText,
                    stateText,
                    expiresInText,
                    idTokenText,
                },
                {
                    timeout: 10000,
                }
            )
            .then((response) => {
                setResponse(response.data);
            })
            .catch((err) => {
                if (err.response.data.Errors.length > 0) {
                    setError((prev) => ({ ...prev, data: err.response.data.Errors }));
                    // @ts-ignore
                    const customError = bankIdErrorMapper[err.response.data.Errors[0].ErrorCode];
                    if (!customError) {
                        setError((prev) => ({
                            ...prev,
                            isBankIdError: true,
                        }));
                    }
                } else if (
                    err?.code === 'ERR_BAD_REQUEST' &&
                    err?.response?.data?.MessageShort === "Can't add a new data to the completed case!"
                ) {
                    setError((prev) => ({ ...prev, isInvalid: true }));
                } else {
                    setError((prev) => ({
                        ...prev,
                        isBankIdError: true,
                    }));
                }
            })
            .finally(() => setIsLoading(false));
    }, [accessToken, tokenTypeText, idTokenText, stateText, expiresInText]);

    return (
        <>
            {!!response ? (
                <BankIdConfirmation bankIdResponse={response} processId={stateText} />
            ) : (
                <Layout {...errorLayoutProps(error.isInvalid && !isLoading, t, isCustomError, error.isBankIdError)}>
                    <div className="processingContent">
                        {isLoading ? (
                            <Loader size="medium" />
                        ) : (
                            <ProcessingErrors stateText={stateText} error={error} />
                        )}
                    </div>
                </Layout>
            )}
        </>
    );
};
