import { useSearchParams } from 'react-router-dom';
import { Box, Layout, List } from '../../components';
import lightBulb from '../../static/images/icons/lightBulb.svg';
import { Button } from '@simplea/shared-ui';
import './ClientPortalIdentification.styles.css';
import { useEffect } from 'react';
import axios from 'axios';
import { Environment, getBaseUrl, getEnvironment } from '../../utils';
import { constants } from '../../utils/constants';
import { useTranslation } from 'react-i18next';
import { TFunction } from 'i18next';

const portalLink = (t: TFunction<'translation', undefined>): { [key in Environment]: string } => ({
    Test: t('clientPortalLinkTest'),
    Fix: t('clientPortalLinkFix'),
    Prod: t('clientPortalLinkProd'),
});

const listItems = (t: TFunction<'translation', undefined>): string[] => [
    t('clientPortal.list.1'),
    t('clientPortal.list.2'),
    t('clientPortal.list.3'),
    t('clientPortal.list.4'),
];

export const ClientPortalIdentification = () => {
    const [searchParams] = useSearchParams();
    const { t } = useTranslation();

    const processId = searchParams.get(constants.PROCESS_ID_QUERY_PARAM);

    useEffect(() => {
        if (processId) {
            axios
                .post(
                    `${getBaseUrl()}/api/client-aml-identification/confirm-identification-data-by-portal`,
                    {
                        caseBaseExternalId: processId,
                    },
                    {
                        timeout: 10000,
                    }
                )
                .catch((error: any) => {
                    console.error('Error: ', error);
                });
        }
    }, [processId]);

    return (
        <Layout headline={t('clientPortal.headline')} description={t('clientPortal.description')}>
            <Box>
                <h3 className="title">{t('clientPortal.headline')}</h3>
                <div className="clientPortalListContent">
                    <List items={listItems(t)} />
                    <div className="clientPortalImportant">
                        <img src={lightBulb} alt="lightBulb" />
                        <b>{t('clientPortal.important')}</b>
                    </div>
                </div>
                <div className="redirectButton">
                    <Button onClick={() => window && window.open(portalLink(t)[getEnvironment()], '_self')}>
                        {t('clientPortal.buttonText')}
                    </Button>
                </div>
            </Box>
        </Layout>
    );
};
