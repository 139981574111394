import React, { Fragment, useContext, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import axios, { AxiosResponse } from 'axios';
import { Box, List } from '../../../components';
import { Layout } from '../../../components/Poland/Layout/Layout';
import { ReactComponent as Question } from '../../../static/images/question.svg';
import { MojeIdCheckRequestStatusResponse } from '../../../utils/types';
import { MojeIdCreateSamlResponse } from '../types';
import { MojeIdContext } from '../../../context/MojeIdContext';
import usePolandParams from '../../../hooks/usePolandParams';
import MojeIdPopupInfo from './MojeIdPopupInfo';
import SmsSendedPopup from './SmsSendedPopup';
import { getBaseUrl } from '../../../utils';
import { ROUTES } from '../../routes';
import Button from './Button';
import Loader from './Loader';
import './Welcome.styles.css';

const Welcome = () => {
    const formRef = useRef<HTMLFormElement>(null);

    const { t } = useTranslation();
    const navigate = useNavigate();
    const [loading, setLoading] = useState(false);
    const [showMojeIdInfo, setShowMojeIdInfo] = useState(false);
    const [showSmsSendedPopup, setShowSmsSendedPopup] = useState(false);
    const { onlinePolicyExternalId, participantExternalId } = usePolandParams(true);
    const { clientData, setMojeIdData, setMojeIdValidate, setClientData } = useContext(MojeIdContext);
    const [response, setResponse] = useState<MojeIdCreateSamlResponse | null>(null);

    const pageTranslation: any = t('home.card', { returnObjects: true });

    useEffect(() => {
        if (onlinePolicyExternalId && participantExternalId) {
            setLoading(true);
            axios
                .post<MojeIdCheckRequestStatusResponse>(`${getBaseUrl()}/api/moje-id/check-request-status`, {
                    onlinePolicyExternalId,
                    participantExternalId,
                })
                .then((response) => response.data)
                .then((result) => {
                    if (result.IsConfirmed === true) {
                        navigate({
                            pathname: ROUTES.confirmed,
                            search: `?onlinePolicyExternalId=${onlinePolicyExternalId}&participantExternalId=${participantExternalId}`,
                        });
                    }
                    if (result.IsOnlinePolicyExternalIdValid === false) {
                        navigate(ROUTES.error);
                    }
                    setLoading(false);
                })
                .catch(() => {
                    navigate('/moje-id/not-found');
                });
        }
    }, [onlinePolicyExternalId, participantExternalId, navigate]);

    const handleShowMojeIdInfo = () => {
        setShowMojeIdInfo((prev) => !prev);
    };

    const handleContinue = () => {
        setLoading(true);
        axios<any, AxiosResponse<MojeIdCreateSamlResponse>>({
            method: 'post',
            url: `${getBaseUrl()}/api/moje-id/create-saml-authnrequest`,
            data: {
                onlinePolicyExternalId,
                participantExternalId,
            },
        })
            .then((response) => setResponse(response.data))
            .catch((err) => {})
            .finally(() => setLoading(false));
    };

    // const handleSendSmsForIdScan = () => {
    //     setProcessing(true);
    //     axios
    //         .post<MojeIdCheckRequestStatusResponse>(
    //             `${getBaseUrl()}/api/moje-id/generate-online-policy-attachment-code`,
    //             {
    //                 onlinePolicyExternalId,
    //             }
    //         )
    //         .then((response) => response.data)
    //         .then(() => setShowSmsSendedPopup(true))
    //         .catch((err) => console.error(err))
    //         .finally(() => setProcessing(false));
    // };

    useEffect(() => {
        if (clientData) {
            setMojeIdData(null);
            setMojeIdValidate(null);
            setClientData(null);
        }
        // eslint-disable-next-line
    }, [clientData]);

    useEffect(() => {
        if (response && formRef && formRef.current) {
            setResponse(null);
            formRef?.current?.submit();
        }
    }, [response, formRef]);

    return (
        <Layout
            headline={
                <div className="welcomeTitle" data-test="welcomeTitle">
                    {t('home.title')}
                </div>
            }
            description={t('home.subtitle')}
            footerContent={
                <Fragment>
                    {/* <Button
                        outlined
                        disabled={processing}
                        onClick={handleSendSmsForIdScan}
                        data-test="sendSmsForIdScan"
                    >
                        {t('home.sendSmsForIdScan')}
                    </Button> */}
                    <Button
                        variant="primary"
                        // disabled={processing}
                        onClick={handleContinue}
                        data-test="continueWithMojeIdButton"
                    >
                        {t('home.continueWithMojeId')}
                    </Button>
                </Fragment>
            }
        >
            {loading && <Loader overflow size="medium" className="mt-[50%]" />}

            <Box data-test="mojeIdCard">
                <h3 className="flex justify-center" data-test="mojeIdCardTitle">
                    {t('home.card.title')}
                    <Question
                        className="ml-5 pointer min-w-8 min-h-8"
                        data-test="mojeIdCardInfo"
                        onClick={handleShowMojeIdInfo}
                    />
                </h3>

                {showMojeIdInfo && <MojeIdPopupInfo onClose={handleShowMojeIdInfo} />}

                <List items={pageTranslation.list} data-test="mojeIdList" />
            </Box>

            <div className="hidden">
                <form
                    ref={formRef}
                    method="post"
                    action={response?.MojeIdRedirectUrl}
                    encType="application/x-www-form-urlencoded"
                >
                    <input type="hidden" name={response?.FormParamName} value={response?.FormParamValueBase64} />
                    <button type="submit">Submit</button>
                </form>
            </div>

            {showSmsSendedPopup && <SmsSendedPopup onClose={() => setShowSmsSendedPopup(false)} />}
        </Layout>
    );
};

export default Welcome;
