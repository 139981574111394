import { Button } from '@simplea/shared-ui';
import { Dispatch, SetStateAction } from 'react';

type Props = {
    setIsOpen: Dispatch<SetStateAction<boolean>>;
    leaveButtonText: string;
    onLeave: () => void;
};

export const LeavingModalFooter = ({ setIsOpen, leaveButtonText, onLeave }: Props) => (
    <div className="leavingModalFooter">
        <Button onClick={() => setIsOpen(false)}>Pokračovat</Button>

        <Button variant="secondary" onClick={onLeave}>
            {leaveButtonText}
        </Button>
    </div>
);
