import file from '../../static/images/icons/file.svg';
import arrowDown from '../../static/images/icons/arrowDown.svg';
import './FormIdentification.styles.css';
import { useTranslation } from 'react-i18next';

type Props = {
    name: string;
    link: string;
};

export const DownloadDocument = ({ name, link }: Props) => {
    const { t } = useTranslation();
    return (
        <div className="documentsWrap">
            <div className="documentsDownload">
                <img src={file} alt="file" />
                <p>{name}</p>
            </div>
            <a href={link} download={name} className="downloadLink documentsDownload">
                <img src={arrowDown} alt="file" />
                {t('form.download')}
            </a>
        </div>
    );
};
