import React, { Fragment, ReactNode, useState } from 'react';
import cn from 'classnames';
import { ReactComponent as ClearIcon } from '../../static/images/icons/clear.svg';

interface MobileTooltipProps {
    title?: string;
    content: ReactNode;
    children?: ReactNode;
}

const MobileTooltip = ({ title, content, children, ...props }: MobileTooltipProps) => {
    const [displayContent, setDisplayContent] = useState(false);

    return (
        <Fragment>
            <div
                className="pointer"
                onClick={(e) => {
                    setDisplayContent(true);
                    e.preventDefault();
                }}
                {...props}
            >
                {children}
            </div>
            {displayContent && (
                <Fragment>
                    <div
                        className={'fixed bottom-0 left-0 right-0 bg-white p-8 z-60 text-base text-left rounded-t-xs'}
                        data-test="mobileTooltipWrapper"
                    >
                        <div className={cn('relative pr-2')} data-test="mobileTooltipContent">
                            {title && (
                                <div className="pb-4 text-xl" data-test="mobileTooltipTitle">
                                    {title}
                                </div>
                            )}
                            <div
                                className="absolute right-[-16px] top-[-16px] pointer"
                                onClick={() => setDisplayContent(false)}
                                data-test="mobileTooltipCloseButton"
                            >
                                <ClearIcon />
                            </div>
                            {content}
                        </div>
                    </div>
                    <div className="fixed bottom-0 left-0 right-0 top-0 z-50 bg-black opacity-90" />
                </Fragment>
            )}
        </Fragment>
    );
};

export default MobileTooltip;
