import { ReactNode, useCallback, useEffect, useState } from 'react';
import MobileTooltip from './MobileTooltip';
import DesktopTooltip from './DesktopTooltip';
import { isTouchDevice } from '../../utils';
import './Tooltip.styles.css';

export interface TooltipProps {
    title?: string;
    content: ReactNode;
    width?: 'small' | 'large';
    className?: string;
    children: ReactNode;
}

const Tooltip = (props: TooltipProps) => {
    const [smallView, setSmallView] = useState(false);

    const updateWindowDimensions = useCallback(() => {
        const isSmallView = isTouchDevice();
        if (isSmallView !== smallView) {
            setSmallView(isSmallView);
        }
        // eslint-disable-next-line
    }, [smallView, isTouchDevice]);

    useEffect(() => {
        window.addEventListener('resize', updateWindowDimensions);

        return () => {
            window.removeEventListener('resize', updateWindowDimensions);
        };
    }, [updateWindowDimensions]);

    useEffect(() => {
        updateWindowDimensions();
    }, [updateWindowDimensions]);

    if (smallView) {
        return <MobileTooltip {...props} />;
    }

    return <DesktopTooltip {...props} />;
};

export default Tooltip;
