import { useTranslation } from 'react-i18next';
import { Box, Layout, List } from '../../components';
import { DownloadDocument } from './DownloadDocument';
import { TFunction } from 'i18next';

const listItems = (t: TFunction<'translation', undefined>) => [t('form.list.1'), t('form.list.2'), t('form.list.3')];

export const FormIdentification = () => {
    const { t } = useTranslation();

    return (
        <Layout headline={t('form.headline')} description={t('form.description')}>
            <Box>
                <h3 className="title">{t('form.headline')}</h3>
                <div className="formListContent">
                    <List items={listItems(t)} />
                </div>
                <DownloadDocument name={t('form.amlFileName')} link={t('form.amlLink')} />
                <DownloadDocument name={t('form.pepFileName')} link={t('form.pepLink')} />
            </Box>
        </Layout>
    );
};
