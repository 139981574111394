import logo from '../../static/images/simplea-logo.png';
import logoRetina from '../../static/images/simplea-logo@2x.png';
import { useModal } from '../Modal/useModal';
import { LeavingModalContent } from '../Modal/content/LeavingModalContent';
import { LeavingModalFooter } from '../Modal/footers/LeavingModalFooter';
import { useTranslation } from 'react-i18next';

type Props = {
    isBankId?: boolean;
};

export const Logo = ({ isBankId = false }: Props) => {
    const { t } = useTranslation();
    const { isOpen, setIsOpen, Modal } = useModal();
    const isModalOpenable = isOpen && isBankId;
    return (
        <>
            {isModalOpenable && (
                <Modal
                    content={
                        <LeavingModalContent
                            text="Opuštěním této stránky nebude proces identifikace přes BankId dokončen. V
          případě, že chcete identifikaci dokončit, klikněte na Pokračovat. V
          případě opuštění stránky můžete proces identifikace přes BankId zopakovat
          klikem na odkaz v e-mailu."
                        />
                    }
                    setIsOpen={setIsOpen}
                    heading="Opravdu chcete odejít?"
                    headingSize="xl"
                    footer={
                        <LeavingModalFooter
                            onLeave={() => window && window.open('https://www.simplea.cz', '_self')}
                            leaveButtonText="Přejít na web Simplea.cz"
                            setIsOpen={setIsOpen}
                        />
                    }
                />
            )}
            <img
                src={logo}
                srcSet={`${logo}, ${logoRetina} 2x`}
                alt="Simplea logo"
                className="logo"
                onClick={() => {
                    isBankId ? setIsOpen(true) : window && window.open(t('marketingWebLink'), '_self');
                }}
            />
        </>
    );
};
