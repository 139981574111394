import React, { useContext } from 'react';
import { MojeIdContext } from '../../context/MojeIdContext';
import FormikSelectInput from '../Input/FormikSelectInput';

interface SelectCountryProps {
    name: string;
    label: string;
    disabled?: boolean;
    required?: boolean;
}

interface CountryType {
    OrderNo: number;
    Id: number;
    Code: string;
    NameTranslated: string;
}

export const SelectCountry = (props: SelectCountryProps) => {
    const { initData } = useContext(MojeIdContext);

    const { Countries } = initData?.Lovs || {};

    return (
        <FormikSelectInput
            isCenter
            options={(Countries || []).map((country: CountryType) => ({
                value: country.Code,
                label: country.NameTranslated,
            }))}
            {...props}
        />
    );
};
