import { List } from '../../List/List';

const listItems = [
    'Služba BankID je podporovaná bankami, pro banky je zabezpečení prioritou. Banka, přes kterou se ověřujete, odpovídá za bezpečí ověření.',
    'Nemusíte mít obavu používat BankID jako univerzální řešení pro různé účely, včetně přihlášení do klientské zóny, sjednání produktu či služby online.',
    'Služba je bezpečná u všech poskytovatelů, kteří službu podporují.',
    'Služba BankID ani poskytovalé (např. pojišťovny apod.), pro které se přes BankID přihlašujete a ověřujete, nemohou získat informace o Vašich přihlašovacích údajích, ani o Vašich financích.',
    'Při použití BankID máte vždy kontrolu nad tím, jaké údaje předáváte poskytovatelům v rámci vyjádření souhlasu s předáním osobních údajů.',
];

export const BankIdModalContent = () => {
    return (
        <div className="bankIdModalContent">
            <p>
                BankID je služba, pomocí které jednoduše online ověříte svou identitu nebo si pohodlně sjednáte služby a
                produkty - je to alternativa k občanskému průkazu pro online ověření, že jste to opravdu Vy. Stačí se
                přihlásit tak, jak jste zvyklí ze svého internetového bankovnictví.
            </p>
            <div className="clientPortalListContent">
                <List items={listItems} />
            </div>
        </div>
    );
};
