import { Dispatch, SetStateAction } from 'react';
import { CheckBox } from '../../../../components/Checkbox/Checkbox';
import { PepModalContent } from '../../../../components/Modal/content/PepModalContent';
import { useModal } from '../../../../components/Modal/useModal';

type Props = {
    isPep: boolean;
    setIsPep: Dispatch<SetStateAction<boolean>>;
};

export const IsPepCheckbox = ({ isPep, setIsPep }: Props) => {
    const { isOpen, setIsOpen, Modal } = useModal();

    const togglePep = () => {
        setIsPep(!isPep);
    };

    return (
        <>
            <div className="checkboxWrap">
                <CheckBox className="checkButton" onClick={togglePep} checked={isPep} />
                <div className="pepLabel" onClick={togglePep}>
                    Já nebo blízká osoba je Politicky exponovaná
                </div>
                <div
                    className="help"
                    onClick={() => {
                        setIsOpen(true);
                    }}
                >
                    ?
                </div>
            </div>
            {isOpen && (
                <Modal
                    content={<PepModalContent />}
                    setIsOpen={setIsOpen}
                    heading="Politicky exponovaná osoba"
                    headingSize="xl"
                />
            )}
        </>
    );
};
