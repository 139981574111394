import { Button } from '@simplea/shared-ui';
import { Layout as LayoutCS } from '../../components';
import { Layout as LayoutPL } from '../../components/Poland';
import working from '../../static/images/working.png';
import { useTranslation } from 'react-i18next';
import { CSSProperties } from 'react';
import { Language } from '../../i18n';
import { DefaultFooter } from '../../components';

const wrapStyles: CSSProperties = {
    height: '45vh',
    display: 'flex',
    flexDirection: 'column',
};

interface NotFoundProps {
    language: string;
}

export const NotFound = ({ language }: NotFoundProps) => {
    const { t } = useTranslation();

    const LayoutComponent = language === Language.PL ? LayoutPL : LayoutCS;

    return (
        <LayoutComponent
            headline={t('404.headline')}
            description={t('404.description')}
            footerContent={<DefaultFooter />}
        >
            <div className="container">
                <div style={wrapStyles}>
                    <img src={working} alt="working" width="365" className="m-auto" data-test="notFoundImage" />
                    <Button
                        onClick={() => window && window.open(t('marketingWebLink'), '_self')}
                        className="w-fit-content mx-auto"
                        data-test="notFoundButton"
                    >
                        {t('simpleaRedirect')}
                    </Button>
                </div>
            </div>
        </LayoutComponent>
    );
};
