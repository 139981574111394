import React, { Fragment, useContext, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { Form, FormikProps } from 'formik';
import { Box, FormikTextInput } from '../../../components';
import { Layout } from '../../../components/Poland/Layout/Layout';
import usePolandParams from '../../../hooks/usePolandParams';
import ChangeBankPopup from './ChangeBankPopup';
import { MojeIdContext } from '../../../context/MojeIdContext';
import FormikDateInput from '../../../components/DateInput/FormikDateInput';
import { MojeIdGetClientDataIdCard } from '../../../utils/types';
import { SelectIdCardType } from './SelectIdCardType';
import { constants, ErrorCodes } from '../../../utils/constants';
import RequiredInputsInfo from './RequiredInputsInfo';
import MissingMojeIdDataError from './MissingMojeIdDataError';
import { getMissingFormData, nowDate, subtractDate } from '../../../utils';
import Button from './Button';

export interface ConfirmIdData {
    documentType?: number;
    idNumber?: string;
    issueDate?: string;
    expirationDate?: string;
    issuedBy?: string;
}

export interface RequiredConfirmIdData extends Required<ConfirmIdData> {}

interface ConfirmIdFormProps extends FormikProps<RequiredConfirmIdData> {}

const ConfirmIdForm = ({ values, handleSubmit }: ConfirmIdFormProps) => {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const { mojeIdData, mojeIdValidate, clientData, setClientData } = useContext(MojeIdContext);
    const [displayChangeBankPopup, setDisplayChangeBankPopup] = useState(false);
    const { onlinePolicyExternalId, participantExternalId } = usePolandParams(true);

    const mojeIdIdCard = mojeIdData && (mojeIdData?.IdCards || []).length > 0 ? mojeIdData.IdCards[0] : null;
    const clientIdCard = clientData && (clientData?.IdCards || []).length > 0 ? clientData.IdCards[0] : null;

    const expirationDateInvalid = useMemo(
        () => mojeIdValidate && mojeIdValidate?.ErrorCodes?.includes(ErrorCodes.InvalidIdCard),
        [mojeIdValidate]
    );

    const issuedDateMissing = useMemo(
        () => mojeIdValidate && mojeIdValidate?.ErrorCodes?.includes(ErrorCodes.MissingIssueDtIdCard),
        [mojeIdValidate]
    );

    const handleChangeBank = () => {
        setDisplayChangeBankPopup(true);
    };

    const handleGoBack = () => {
        navigate(
            `/moje-id?onlinePolicyExternalId=${onlinePolicyExternalId}&participantExternalId=${participantExternalId}`
        );
    };

    useEffect(() => {
        if (clientData) {
            const newIdCard: MojeIdGetClientDataIdCard = {
                UnlimitedValidity: false,
                ...(clientIdCard || {}),
                Kind: values.documentType,
                IdCardNo: values.idNumber,
                IssuedDt: values.issueDate,
                ExpiryDt: values.expirationDate,
                Issuer: values.issuedBy,
            };
            const newIdCards =
                clientData && clientIdCard ? clientData.IdCards.map((v, i) => (i === 0 ? newIdCard : v)) : [newIdCard];
            setClientData({
                ...clientData,
                IdCards: newIdCards,
            });
        }
        // eslint-disable-next-line
    }, [values]);

    const requiredFields = {
        documentType: t('confirmId.documentType'),
        idNumber: t('confirmId.idNumber'),
        expirationDate: t('confirmId.expirationDate'),
    };

    const missingFormData = getMissingFormData(requiredFields, values);

    return (
        <Layout
            headline={t('confirmId.title')}
            description={t('confirmId.subtitle')}
            footerContent={
                <Fragment>
                    <Button variant="primary" outlined data-test="changeBankButton" onClick={handleChangeBank}>
                        {t('confirmId.changeBank')}
                    </Button>
                    <Button
                        variant="primary"
                        disabled={!!expirationDateInvalid || missingFormData.length > 0}
                        data-test="confirmValuesButton"
                        onClick={() => handleSubmit()}
                    >
                        {t('confirmId.confirmValues')}
                    </Button>
                </Fragment>
            }
        >
            <MissingMojeIdDataError fields={requiredFields} />

            <Box data-test="confirmIdCard">
                <RequiredInputsInfo />

                <h3 className="m-0" data-test="confirmIdCardTitle">
                    {t('confirmId.idDocumentTitle')}
                </h3>
                <Form style={{ width: '100%' }}>
                    <div className="grid">
                        <SelectIdCardType
                            name="documentType"
                            label={t('confirmId.documentType')}
                            disabled
                            required
                            data-test="documentType"
                        />
                        <FormikTextInput
                            name="idNumber"
                            label={t('confirmId.idNumber')}
                            disabled
                            required
                            data-test="idNumber"
                        />
                        <FormikDateInput
                            name="issueDate"
                            label={t('confirmId.issueDate')}
                            disabled={!!mojeIdIdCard?.IssuedDt}
                            minDate={new Date(subtractDate(constants.MAX_DOCUMENT_AGE, 'years'))}
                            maxDate={new Date(nowDate())}
                            required
                            errorMessage={
                                issuedDateMissing && !values.issueDate ? t('confirmId.issueDateMissing') : undefined
                            }
                            data-test="issueDate"
                        />
                        <FormikDateInput
                            name="expirationDate"
                            label={t('confirmId.expirationDate')}
                            disabled
                            required
                            errorMessage={expirationDateInvalid ? t('confirmId.expirationDateInvalid') : undefined}
                            data-test="expirationDate"
                        />
                        <FormikTextInput
                            name="issuedBy"
                            label={t('confirmId.issuedBy')}
                            required
                            disabled={mojeIdIdCard?.Issuer !== ''}
                            data-test="issuedBy"
                        />
                    </div>
                </Form>
            </Box>
            {displayChangeBankPopup && (
                <ChangeBankPopup onSubmit={handleGoBack} onClose={() => setDisplayChangeBankPopup(false)} />
            )}
        </Layout>
    );
};

export default ConfirmIdForm;
