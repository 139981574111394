import { ReactNode } from 'react';
import './Label.styles.css';

type Props = {
    label: string;
    children: ReactNode;
    className?: string;
    htmlFor?: string;
};

export const Label = ({ label, children, className = '' }: Props) => (
    <label className={`labelWrap ${className}`}>
        {label}
        {children}
    </label>
);
