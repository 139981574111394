export const BankIdFooter = () => (
    <div
        style={{
            position: 'relative',
            top: '-70px',
        }}
    >
        <h4>Nenašli jste svou banku?</h4>
        <p>
            Vaše banka bohužel nepodporuje službu BankID. Je nám líto, ale přes Vaši banku zatím nelze BankID využít.
            Pro pokračování můžete vybrat jinou banku, u které jste klientem. Seznam bank, které jsou v přípravě,
            najdete na{' '}
            <a href="https://www.bankid.cz/" target="_blank" rel="noreferrer">
                webu BankID
            </a>
            .
        </p>
    </div>
);
