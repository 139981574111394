import { ReactNode } from 'react';
import { Logo } from './Logo';
import Footer from './Footer';
import './Layout.styles.css';

type Props = {
    children: ReactNode;
    headline?: ReactNode;
    description?: string;
    isBankId?: boolean;
};

export const Layout = ({ children, headline, description, isBankId = false }: Props) => {
    return (
        <div className="layout">
            <header className="navbar">
                <Logo isBankId={isBankId} />
            </header>
            <main className="main">
                <div className="container">
                    <div className="headline">{headline}</div>
                    <p className="description">{description}</p>
                    {children}
                </div>
            </main>
            <Footer isBankId={isBankId} />
        </div>
    );
};
