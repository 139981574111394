import { Route, Routes } from 'react-router-dom';
import { BankIdIntro } from './routes/BankId/BankIdIntro/BankIdIntro';
import { FormIdentification } from './routes/FormIdentification/FormIdentification';
import { ClientPortalIdentification } from './routes/ClientPortalIdentification/ClientPortalIdentification';
import { ROUTES } from './routes/routes';
import { Processing } from './routes/BankId/Processing/Processing';
import { NotFound } from './routes/Errors/NotFound';
import i18n, { Language } from './i18n';
import { Fragment, useEffect } from 'react';
import { Environment, getEnvironment, isDevelopment } from './utils';
import MojeId from './routes/MojeId';
import Error400 from './routes/Errors/Error400';

enum TestingCountry {
    CZ = 'cz',
    SK = 'sk',
    PL = 'pl',
}

const mapCountry: { [key in TestingCountry]?: string } = {
    cz: Language.CS,
    sk: Language.SK,
    pl: Language.PL,
};

const testingCountryFromUrl = (url: string) => url.split('simide')[1]?.substring(0, 2) as TestingCountry;

const url = window.location.hostname;
const splittedUrl = url.split('.');

const languageByCountry = () => {
    return process.env.REACT_APP_COUNTRY === 'pl' ? Language.PL : Language.CS;
};

const language = (): string => {
    if (getEnvironment() === Environment.Prod) {
        return mapCountry[splittedUrl[splittedUrl.length - 1] as TestingCountry] || Language.CS;
    } else if (getEnvironment() === Environment.Fix || getEnvironment() === Environment.Test) {
        return isDevelopment() ? languageByCountry() : mapCountry[testingCountryFromUrl(url)] || Language.CS;
    } else {
        return languageByCountry();
    }
};

function App() {
    console.log('language:', language());
    useEffect(() => {
        if (language() === Language.SK) {
            i18n.changeLanguage(Language.SK);
        } else if (language() === Language.PL) {
            i18n.changeLanguage(Language.PL);
        } else {
            i18n.changeLanguage(Language.CS);
        }
    }, []);

    return (
        <div className={language()}>
            <Routes>
                {(language() === Language.CS || language() === Language.SK) && (
                    <Fragment>
                        <Route path={ROUTES.clientPortal} element={<ClientPortalIdentification />} />
                        <Route path={ROUTES.form} element={<FormIdentification />} />
                    </Fragment>
                )}
                {/* BankID */}
                {language() === Language.CS && (
                    <>
                        <Route path={ROUTES.bankIdProcessing} element={<Processing />} />
                        <Route path={ROUTES.bankIdIntro} element={<BankIdIntro />} />
                    </>
                )}

                {/* MojeID */}
                {language() === Language.PL && (
                    <Route path={ROUTES.mojeid} element={<MojeId language={language()} />} />
                )}

                <Route path={ROUTES.error} element={<Error400 />} />
                <Route path={ROUTES.notFound} element={<NotFound language={language()} />} />
            </Routes>
        </div>
    );
}

export default App;
