import React from 'react';
import { Trans, useTranslation } from 'react-i18next';
import PopupWrapper from '../../../components/Popup/PopupWrapper';
import PopupButtons from '../../../components/Popup/PopupButtons';
import MojeIdLogo from '../../../static/images/mojeid-logo.svg';
import Button from './Button';

interface MojeIdPopupInfoProps {
    onClose: () => void;
}

const MojeIdPopupInfo = ({ onClose }: MojeIdPopupInfoProps) => {
    const { t } = useTranslation();

    const popupItems: any = t('home.popupInfo.items', { returnObjects: true });

    return (
        <PopupWrapper
            color="lightPurple"
            size="md2"
            className="md:px-16 md:py-12 max-h-5/6 overflow-auto"
            onClose={onClose}
            data-test="mojeIdInfoPopup"
        >
            <img src={MojeIdLogo} alt="" className="mx-auto mb-0 h-auto w-[14rem]" data-test="popupImage" />

            <div className="mb-8 flex flex-col gap-y-6 text-left" data-test="infoList">
                {popupItems.map((p: { title: string; text: string }, i: number) => (
                    <div key={i} data-test={`infoListItem${i}`}>
                        <strong className="mb-2 font-medium">{p.title}</strong>
                        <p>
                            <Trans
                                i18nKey={`home.popupInfo.items.${i}.text`}
                                components={{
                                    link1: (
                                        <a
                                            href="https://www.mojeid.pl/#dostawcy-uslug"
                                            className="font-medium underline color-purple-dark"
                                            target="_blank"
                                            rel="noreferrer"
                                            data-test="mojeIdLink"
                                        >
                                            .
                                        </a>
                                    ),
                                }}
                            />
                        </p>
                    </div>
                ))}
            </div>

            <PopupButtons align="center" data-test="popupButtons">
                <Button onClick={onClose} data-test="closeButton">
                    {t('general.close')}
                </Button>
            </PopupButtons>
        </PopupWrapper>
    );
};

export default MojeIdPopupInfo;
