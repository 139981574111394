import { ReactElement } from 'react';

import { FieldInputProps, useField } from 'formik';

import './Input.styles.css';
import { Loader } from '@simplea/shared-ui';
import NumberFormat from 'react-number-format';
import { ReactComponent as Edit } from '../../static/images/icons/edit.svg';
import { getDataTest } from '../../utils';

export enum InputType {
    TEXT = 'text',
    NUMBER = 'number',
    PRICE = 'price',
    EMAIL = 'email',
    TEL = 'tel',
    DECIMAL = 'decimal',
}

export type InputVariant = Exclude<InputType, InputType.TEL>;

const IntMax = 2147483647;

export interface TextInputProps {
    field: FieldInputProps<string | number>;
    id: string;
    name: string;
    variant?: InputVariant;
    suffix?: string;
    min?: number | string; // string is for date range
    max?: number | string;
    placeholder?: string;
    className?: string;
    autoFocus?: boolean;
    showEditIcon?: boolean;
    loading?: boolean;
    disabled?: boolean;
    readonly?: boolean;
}

export const TextInput = ({
    placeholder,
    suffix,
    min = 0,
    max = 99999999,
    variant = InputType.TEXT,
    autoFocus,
    showEditIcon,
    loading = false,
    disabled = false,
    readonly,
    ...props
}: TextInputProps): ReactElement | null => {
    const [field, meta] = useField(props);
    const { error, touched } = meta;

    return (
        <div className="inputWrap">
            <div className="inputLoadingWrap">
                {loading && (
                    <div className="inputLoader" data-test={getDataTest(props, 'Loading')}>
                        <Loader size="small" />
                    </div>
                )}
                {variant === InputType.PRICE || variant === InputType.NUMBER || variant === InputType.DECIMAL ? (
                    <NumberFormat
                        className={`inputStyles ${!!error && touched ? 'hasError' : ''}`}
                        allowNegative={false}
                        fixedDecimalScale={false}
                        decimalScale={variant === InputType.DECIMAL ? undefined : 0}
                        value={field.value}
                        name={field.name}
                        id={field.name}
                        onChange={field.onChange}
                        data-lpignore="true"
                        disabled={readonly}
                        allowLeadingZeros={false}
                        thousandSeparator=" "
                        decimalSeparator={variant === InputType.DECIMAL ? ',' : undefined}
                        isNumericString
                        placeholder={placeholder}
                        inputMode={variant === InputType.PRICE || variant === InputType.DECIMAL ? 'decimal' : 'numeric'}
                        isAllowed={
                            // cisla a desetina cisla lze zadat jen v rozmezi min, max
                            // cenu neumoznit zadat zapornou, pro maximálni hodnotu pouzit INT_MAX (2 147 483 647),
                            // Number.MAX_SAFE_INTEGER vraci 9 007 199 254 740 992, coz nelze pouzit => pada backend
                            variant === InputType.NUMBER || variant === InputType.DECIMAL
                                ? ({ floatValue }) =>
                                      Number(min) <= (floatValue || 0) && (floatValue || 0) <= Number(max)
                                : variant === InputType.PRICE
                                  ? ({ floatValue }) => 0 <= (floatValue || 0) && (floatValue || 0) <= IntMax
                                  : undefined
                        }
                        data-test={getDataTest(props, 'Input')}
                    />
                ) : (
                    <input
                        data-lpignore="true"
                        className={`inputStyles ${!!suffix ? 'hasSuffix' : ''} ${!!error && touched ? 'hasError' : ''}`}
                        placeholder={placeholder}
                        type={variant}
                        name={field.name}
                        id={field.name}
                        value={field.value || ''}
                        max={max}
                        disabled={readonly || disabled}
                        onChange={field.onChange}
                        autoFocus={autoFocus}
                        data-test={getDataTest(props, 'Input')}
                    />
                )}
                {suffix && (
                    <span className="inputSuffix" data-test={getDataTest(props, 'Suffix')}>
                        {suffix}
                    </span>
                )}
                {showEditIcon && !readonly && <Edit className="editIcon" data-test={getDataTest(props, 'EditIcon')} />}
            </div>
        </div>
    );
};
