import React, { useContext } from 'react';
import FormikSelectInput from '../../../components/Input/FormikSelectInput';
import { MojeIdContext } from '../../../context/MojeIdContext';

interface SelectIdentificatorTypeProps {
    name: string;
    label: string;
    disabled?: boolean;
    required?: boolean;
}

export const SelectIdentificatorType = (props: SelectIdentificatorTypeProps) => {
    const { initData } = useContext(MojeIdContext);

    const { IdentificationTypes } = initData?.Lovs || {};

    return (
        <FormikSelectInput
            isCenter
            options={(IdentificationTypes || []).map((ident: any) => ({
                value: ident.Id,
                label: ident.NameTranslated,
            }))}
            {...props}
        />
    );
};
