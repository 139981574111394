import React from 'react';
import { useTranslation } from 'react-i18next';
import { Layout } from '../../../components/Poland';
import { Invalid } from '../../Invalid/Invalid';
import { DefaultFooter } from '../../../components';

const Confirmed = () => {
    const { t } = useTranslation();

    return (
        <Layout footerContent={<DefaultFooter />} headline={t('confirmed.title')} description={t('confirmed.text')}>
            <div className="processingContent" data-test="confirmed">
                <Invalid />
            </div>
        </Layout>
    );
};

export default Confirmed;
