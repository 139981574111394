import { useEffect } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';

interface UsePolandParamsType {
    onlinePolicyExternalId: string | null;
    participantExternalId: string | null;
    requestIdentifier: string | null;
}

const usePolandParams = (restrict: boolean = false): UsePolandParamsType => {
    const navigate = useNavigate();
    const [searchParams] = useSearchParams();

    const onlinePolicyExternalId = searchParams.get('onlinePolicyExternalId');
    const participantExternalId = searchParams.get('participantExternalId');
    const requestIdentifier = searchParams.get('requestIdentifier');

    useEffect(() => {
        if (restrict && (!onlinePolicyExternalId || !participantExternalId)) {
            navigate('/moje-id/not-found');
        }
    }, [restrict, onlinePolicyExternalId, participantExternalId, navigate]);

    return {
        onlinePolicyExternalId,
        participantExternalId,
        requestIdentifier,
    };
};

export default usePolandParams;
