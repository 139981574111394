import { Field, FormikErrors, FormikTouched } from 'formik';
import { ParticipantProps } from '../../../../utils/types';
import { BankIdConfirmationFormData, BankIdConfirmationFormFields } from '../useBankIdConfirmation';
import { formatDate, validateField } from '../../../../utils';
import { FormikTextInput, Label, PhoneNumberInput, Select, SelectOptionProps, Error } from '@simplea/shared-ui';
import { DataPlaceholder } from '../../../../components';

type Address = { streetName: string; houseNo: string; cityName: string; zip: string; countryCode: string };

type Props = {
    props: {
        participant: ParticipantProps;
        errors: FormikErrors<BankIdConfirmationFormData>;
        touched: FormikTouched<BankIdConfirmationFormData>;
        phoneNumber: string;
        prefixes: SelectOptionProps[];
        countries: SelectOptionProps[];
        isSupportedPrefix: boolean;
        hasBirthPlace: boolean;
        hasCitizenship: boolean;
    };
};

export const PersonalInfo = ({
    props: {
        participant,
        errors,
        phoneNumber,
        touched,
        prefixes,
        countries,
        isSupportedPrefix,
        hasBirthPlace,
        hasCitizenship,
    },
}: Props) => {
    const {
        CityName: ContactCityName,
        StreetName: ContactStreetName,
        Zip: ContactZip,
        CountryCode: ContactCountryCode,
        HouseNo: ContactHouseNo,
    } = participant.ContactAddresses[0] || {
        CityName: '',
        StreetName: '',
        Zip: '',
        CountryCode: '',
    };

    const { CityName, StreetName, Zip, CountryCode, HouseNo } = participant.PermanentAddresses[0];

    const permanentAddress = `${StreetName ? StreetName + ' ' + HouseNo + ',' : ''} ${CityName ? `${CityName + (!StreetName ? ' ' : '')}${!StreetName ? HouseNo : ''}` + ',' : ''} ${Zip ? Zip + ',' : ''} ${CountryCode}`;
    const contactAddress = `${ContactStreetName ? ContactStreetName + ' ' + ContactHouseNo + ',' : ''} ${ContactCityName ? `${ContactCityName + (!ContactStreetName ? ' ' : '')}${!ContactStreetName ? ContactHouseNo : ''}` + ',' : ''} ${ContactZip ? ContactZip + ',' : ''} ${ContactCountryCode}`;

    return (
        <>
            <FormikTextInput label="Titul před" name={BankIdConfirmationFormFields.TitleBefore} />
            <FormikTextInput label="Titul za" name={BankIdConfirmationFormFields.TitleAfter} />
            <DataPlaceholder label="Jméno" className="mobileMargin">
                {participant.PersonalIdentification.FirstName}
            </DataPlaceholder>
            <DataPlaceholder label="Příjmení">{participant.PersonalIdentification.LastName}</DataPlaceholder>
            <FormikTextInput
                label="Email"
                name={BankIdConfirmationFormFields.Email}
                className="mobileMargin"
                error={errors.email}
            />
            <PhoneNumberInput
                prefixOptions={prefixes}
                label="Telefon"
                name={BankIdConfirmationFormFields.Phone}
                prefixName={BankIdConfirmationFormFields.PhonePrefix}
                error={
                    !isSupportedPrefix && !touched.phone
                        ? `Telefonní číslo ${phoneNumber} není námi podporováno, prosíme o vyplnění telefonního čísla včetně předvolby výše.`
                        : errors.phone
                }
            />
            <DataPlaceholder label="Datum narození" className="mobileMargin">
                {formatDate(participant.PersonalIdentification.BirthDt)}
            </DataPlaceholder>
            <FormikTextInput
                label="Místo narození"
                name="birthPlace"
                error={errors.birthPlace}
                readonly={hasBirthPlace}
            />
            <Label htmlFor={BankIdConfirmationFormFields.BirthCountry} label="Stát narození" className="mobileMargin">
                <Field
                    id={BankIdConfirmationFormFields.BirthCountry}
                    name={BankIdConfirmationFormFields.BirthCountry}
                    component={Select}
                    options={countries}
                    validate={(value: string | number) => validateField(value, false)}
                    error={errors.birthCountry}
                />
                <Error isDisplayed={!!errors.birthCountry && touched.birthCountry}>{errors.birthCountry}</Error>
            </Label>
            {hasCitizenship ? (
                <DataPlaceholder label="Státní občanství">
                    {participant.PersonalIdentification.CitizenshipCode}
                </DataPlaceholder>
            ) : (
                <div>
                    <Label label="Státní občanství">
                        <Field
                            id={BankIdConfirmationFormFields.Citizenship}
                            name={BankIdConfirmationFormFields.Citizenship}
                            options={countries}
                            component={Select}
                            error={errors.citizenship}
                        />
                        <Error isDisplayed={!!errors.citizenship && touched.citizenship}>{errors.citizenship}</Error>
                    </Label>
                </div>
            )}
            <div className="confirmationItemCentered">
                <DataPlaceholder label="Druhé státní občanství" className="mobileMargin">
                    {participant.PersonalIdentification.SecondCitizenshipCode}
                </DataPlaceholder>
            </div>
            <div className="confirmationItemCentered">
                <DataPlaceholder label="Trvalá adresa" className="mobileMargin" fullWidth>
                    {permanentAddress}
                </DataPlaceholder>
            </div>
            <div className="confirmationItemCentered">
                <DataPlaceholder label="Kontaktní adresa" className="mobileMargin" fullWidth>
                    {contactAddress}
                </DataPlaceholder>
            </div>
        </>
    );
};
