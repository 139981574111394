import { useNavigate } from 'react-router-dom';
import { ROUTES } from '../../../routes';
import { useModal } from '../../../../components/Modal/useModal';
import { LeavingModalContent } from '../../../../components/Modal/content/LeavingModalContent';
import { LeavingModalFooter } from '../../../../components/Modal/footers/LeavingModalFooter';
import { constants } from '../../../../utils/constants';

type Props = {
    processId: string | null;
};

export const NewBankId = ({ processId }: Props) => {
    const navigate = useNavigate();
    const { isOpen, setIsOpen, Modal } = useModal();
    return (
        <>
            {isOpen && (
                <Modal
                    content={
                        <LeavingModalContent text="V případě, že opustíte tuto stránku, bude nutné projít procesem BankId od začátku. Pokud opravdu chcete odejít, klikněte na oranžové tlačítko níže." />
                    }
                    setIsOpen={setIsOpen}
                    heading="Opravdu chcete odejít?"
                    headingSize="xl"
                    footer={
                        <LeavingModalFooter
                            leaveButtonText="Opustit stránku"
                            onLeave={() =>
                                navigate({
                                    pathname: ROUTES.bankIdIntro,
                                    search: `?${constants.PROCESS_ID_QUERY_PARAM}=${processId}`,
                                })
                            }
                            setIsOpen={setIsOpen}
                        />
                    }
                />
            )}
            <div className="newBankId">
                <div className="text">
                    Narazili jste na jakýkoliv problém s BankID? Nevidíte správná aktuální data nebo některá povinná
                    data chybí?
                    <span
                        className="decoration"
                        onClick={() => {
                            setIsOpen(true);
                        }}
                    >
                        Vyzkoušejte BankID u vaší jiné banky.
                    </span>
                </div>
            </div>
        </>
    );
};
