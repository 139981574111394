import { useFormikContext } from 'formik';
import React from 'react';
import { useTranslation } from 'react-i18next';
import ErrorBox from '../../../components/ErrorBox/ErrorBox';
import { getMissingFormData } from '../../../utils';

interface MissingMojeIdDataErrorProps {
    fields: { [key: string]: string };
}

const MissingMojeIdDataError = ({ fields }: MissingMojeIdDataErrorProps) => {
    const { t } = useTranslation();
    const { values } = useFormikContext<any>();

    const notValuedFields = getMissingFormData(fields, values);

    if (notValuedFields.length === 0) {
        return null;
    }

    return (
        <ErrorBox data-test="missingMojeIdData">
            {t('mojeId.missingData', {
                values: `"${notValuedFields.map((f) => fields[f]).join('", "')}"`,
                interpolation: { escapeValue: false },
            })}
        </ErrorBox>
    );
};

export default MissingMojeIdDataError;
