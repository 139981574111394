import React, { ReactNode } from 'react';

interface ErrorBoxProps {
    children: ReactNode;
}

const ErrorBox = ({ children, ...props }: ErrorBoxProps) => {
    return (
        <div className={'mb-5 rounded-lg bg-orangeLight p-10 text-left'} {...props} data-test="errorBox">
            {children}
        </div>
    );
};

export default ErrorBox;
