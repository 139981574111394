import { ErrorMessage } from 'formik';
import { ReactNode } from 'react';
import cn from 'classnames';

type Props = {
    name?: string;
    className?: string;
    children?: ReactNode;
    isDisplayed?: boolean;
};

export const errorClassName = 'formItemError';

export const Error = ({ name, className, children, isDisplayed = true, ...props }: Props) => {
    if (!isDisplayed) {
        return null;
    }

    if (children) {
        return (
            <div className={cn(errorClassName, className)} {...props}>
                {children}
            </div>
        );
    }

    if (name) {
        return (
            <div className={cn(errorClassName, className)} {...props}>
                <ErrorMessage name={name} />
            </div>
        );
    }

    return null;
};
