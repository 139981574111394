import React from 'react';
import cn from 'classnames';

interface DividerProps {
    className?: string;
}

export const Divider = ({ className }: DividerProps) => {
    return <div className={cn('divider border-purple', className)} />;
};
