import React, { Fragment, ReactNode } from 'react';
import cn from 'classnames';
import { getDataTest } from '../../utils';
import { ReactComponent as CloseIcon } from '../../static/images/close3.svg';

interface PopupWrapperProps {
    color?: 'yellow' | 'lightPurple';
    size?: 'sm' | 'md2';
    className?: string;
    children: ReactNode;
    onClose?: () => void;
}

const PopupWrapper = ({ color = 'yellow', size = 'sm', className, children, onClose, ...props }: PopupWrapperProps) => {
    const cls = cn(
        'fixed left-1/2 top-1/2 w-[calc(100%-12rem)] -translate-x-1/2 -translate-y-1/2 flex flex-col',
        className
    );

    return (
        <Fragment>
            <div
                className={cn(
                    cls,
                    `z-60 max-w-${size} rounded-sm bg-${color || 'yellow'} p-24 text-darkPurple shadow-popup items-center`
                )}
                data-test={getDataTest(props)}
            >
                <div className="relative">
                    {onClose && (
                        <CloseIcon className="absolute right-0 top-0 pointer" onClick={onClose} data-test="closeIcon" />
                    )}
                    {children}
                </div>
            </div>
            <div className="fixed bottom-0 left-0 right-0 top-0 z-50 bg-white opacity-90" />
        </Fragment>
    );
};

export default PopupWrapper;
