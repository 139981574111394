import { Field } from 'formik';
import cn from 'classnames';
import { InputType, InputVariant, TextInput, TextInputProps } from './Input';
import { getDataTest, validateField } from '../../utils';
import { Error } from '../Error/Error';
import './Input.styles.css';

type Props = {
    name: string;
    label?: string;
    loading?: boolean;
    allowFieldValidation?: boolean;
    variant?: InputVariant;
    placeholder?: string;
    suffix?: string;
    className?: string;
    min?: number | string | null;
    max?: number | string | null;
    optional?: boolean;
    disabled?: boolean;
    readonly?: boolean;
    required?: boolean;
    onBlur?: () => void;
};

export const FormikTextInput = ({
    label,
    name,
    loading,
    allowFieldValidation,
    variant = InputType.TEXT,
    optional = true,
    placeholder,
    suffix,
    max = 999999999999,
    min,
    disabled = false,
    readonly,
    required = false,
    className = '',
    ...componentProps
}: Props) => {
    return (
        <div className={cn('textInputWrap', className)} {...componentProps}>
            {label && (
                <label className="myLabel" data-test={getDataTest(componentProps, 'Label')}>
                    {label}
                    {required && <span data-test="symbolRequired"> *</span>}
                </label>
            )}
            <Field
                id={name}
                name={name}
                placeholder={placeholder}
                suffix={suffix}
                variant={variant}
                max={max}
                min={min}
                showEditIcon
                validate={(value: string | number) =>
                    (allowFieldValidation || allowFieldValidation === undefined) &&
                    validateField(value, optional, min, max, variant)
                }
            >
                {(props: JSX.IntrinsicAttributes & TextInputProps) => {
                    return (
                        <TextInput
                            {...props}
                            id={name}
                            key={name}
                            name={name}
                            suffix={suffix}
                            variant={variant}
                            loading={loading}
                            showEditIcon
                            disabled={disabled}
                            readonly={readonly}
                            data-test={getDataTest(componentProps)}
                        />
                    );
                }}
            </Field>
            <Error name={name} data-test={getDataTest(componentProps, 'Error')} />
        </div>
    );
};
