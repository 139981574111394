import React from 'react';
import { useTranslation } from 'react-i18next';
import { Box } from '../../../components';
import Puzzle from '../../../static/images/puzzle.png';
import { Layout } from '../../../components/Poland';
import Button from './Button';

const Finished = () => {
    const { t } = useTranslation();

    const handleSubmit = () => {
        window && window.open(t('marketingWebLink'), '_self');
    };

    return (
        <Layout
            footerContent={
                <Button
                    variant="primary"
                    className="ml-10"
                    data-test="finishedRedirectButton"
                    onClick={() => handleSubmit()}
                >
                    {t('simpleaRedirect')}
                </Button>
            }
        >
            <Box data-test="finishedCard" className="mt-96">
                <div className="flex flex-col items-center md:flex-row">
                    <img
                        src={Puzzle}
                        alt=""
                        className="w-full max-w-[14rem] md:max-w-[24rem] mt-2.5"
                        data-test="finishedImage"
                    />
                    <div className="flex flex-col items-center text-center w-full">
                        <h3 data-test="finishedTitle">{t('finished.title')}</h3>
                        <p data-test="finishedText">{t('finished.text')}</p>
                    </div>
                </div>
            </Box>
        </Layout>
    );
};

export default Finished;
