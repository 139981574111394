import React from 'react';
import { useTranslation } from 'react-i18next';
import { Logo } from '../../components/Logo';
import { ReactComponent as EmailIcon } from '../../static/images/icons/mail.svg';

export const DefaultFooter = () => {
    const { t } = useTranslation();

    return (
        <div className="w-full flex space-between">
            <Logo />
            <div className="emailWrap">
                <div className="emailIcon">
                    <EmailIcon />
                </div>
                <a className="link" href={`mailto:${t('email')}`}>
                    {t('email')}
                </a>
            </div>
        </div>
    );
};
