import React from 'react';

interface PopupButtonsProps {
    align?: 'center' | 'end';
    children: React.ReactNode;
}

const PopupButtons = ({ align = 'end', children, ...props }: PopupButtonsProps) => {
    return (
        <div
            className={`flex flex-col w-full gap-4 sm:flex sm:flex-row sm:items-center justify-${align} mt-2.5`}
            {...props}
        >
            {children}
        </div>
    );
};

export default PopupButtons;
