import { createContext, useEffect, useState } from 'react';
import { MojeIdGetClientDataResponse, MojeIdValidateResponse } from '../utils/types';

export interface MojeIdContextProps {
    initData: any;
    setInitData: (data: any | null) => void;
    mojeIdData: MojeIdGetClientDataResponse | null;
    setMojeIdData: (data: MojeIdGetClientDataResponse | null) => void;
    mojeIdValidate: MojeIdValidateResponse | null;
    setMojeIdValidate: (data: MojeIdValidateResponse | null) => void;
    clientData: MojeIdGetClientDataResponse | null;
    setClientData: (data: MojeIdGetClientDataResponse | null) => void;
}

export const MojeIdContext = createContext<MojeIdContextProps>({
    initData: null,
    setInitData: (data: any | null) => {},
    mojeIdData: null,
    setMojeIdData: (data: MojeIdGetClientDataResponse | null) => {},
    mojeIdValidate: null,
    setMojeIdValidate: (data: MojeIdValidateResponse | null) => {},
    clientData: null,
    setClientData: (data: MojeIdGetClientDataResponse | null) => {},
});

interface ContextProps {
    children: React.ReactNode;
}

export const Context = ({ children }: ContextProps) => {
    const [initData, setInitData] = useState<any | null>(null);
    const [mojeIdData, setMojeIdData] = useState<MojeIdGetClientDataResponse | null>(null);
    const [mojeIdValidate, setMojeIdValidate] = useState<MojeIdValidateResponse | null>(null);
    const [clientData, setClientData] = useState<MojeIdGetClientDataResponse | null>(null);

    useEffect(() => {
        if (mojeIdData && !clientData) {
            setClientData(mojeIdData);
        }
    }, [mojeIdData, clientData]);

    return (
        <MojeIdContext.Provider
            value={{
                initData,
                setInitData,
                mojeIdData,
                setMojeIdData,
                mojeIdValidate,
                setMojeIdValidate,
                clientData,
                setClientData,
            }}
        >
            {children}
        </MojeIdContext.Provider>
    );
};
