import { useCallback, useMemo, useState } from 'react';
import { BankIdData, BankIdInitData, ParticipantProps } from '../../../utils/types';
import axios from 'axios';
import { formatPhoneNumber, formatSimpleaDate, getBaseUrl, parseJson } from '../../../utils';
import { validateYupSchema, yupToFormErrors } from 'formik';
import { validationSchemaDefault } from './validations';
import dayjs from 'dayjs';

export enum BankIdConfirmationFormFields {
    TitleBefore = 'titleBefore',
    TitleAfter = 'titleAfter',
    Email = 'email',
    Phone = 'phone',
    PhonePrefix = 'phonePrefix',
    BirthPlace = 'birthPlace',
    BirthCountry = 'birthCountry',
    Citizenship = 'citizenship',
    IssueDate = 'issueDate',
    Issuer = 'issuer',
    BankAccountCode = 'bankAccountCode',
    BankAccountNo = 'bankAccountNo',
    BankAccountPrefix = 'bankAccountPrefix',
}

type ModelRequestBodyType = Omit<BankIdData, 'BankIdIdentificationInitData'>;

export type BankIdConfirmationFormData = {
    [key in BankIdConfirmationFormFields]?: string | null;
};

export const useBankIdConfirmation = (response: BankIdData) => {
    const [submitted, setSubmitted] = useState(false);
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [isPep, setIsPep] = useState(false);
    const [isIdentificationSuccessful, setIsIdentificationSuccessful] = useState(false);

    const participant: ParticipantProps = parseJson(response.ClientPersonDtoJson);

    const initData: BankIdInitData = parseJson(response.BankIdIdentificationInitData);

    const countries = useMemo(
        () =>
            initData.Lovs.Countries.map(({ NameTranslated, Code }) => ({
                label: NameTranslated,
                value: Code,
            })),
        [initData.Lovs.Countries]
    );

    const banks = useMemo(
        () => [
            { label: 'Vyberte...', value: '' }, // Prázdná hodnota
            ...initData.Banks.map(({ Name, BankCode }) => ({
                label: `${BankCode} - ${Name}`,
                value: BankCode,
            })),
        ],
        [initData.Banks]
    );

    const prefixes = useMemo(
        () =>
            initData.InternationalPhonePrefixes.map(({ PhonePrefix }) => ({
                label: PhonePrefix,
                value: PhonePrefix,
            })),
        [initData.InternationalPhonePrefixes]
    );

    const lovs = {
        countries,
        banks,
        prefixes,
    };

    const issueDate = participant.IdCards[0]?.IssuedDt;
    const hasIssueDate = issueDate && issueDate !== null;

    const onSubmit = useCallback(
        (values: BankIdConfirmationFormData) => {
            setIsSubmitting(true);
            const { BankIdIdentificationInitData, ...restOfResponse } = response;

            const ClientPersonDtoJson: ParticipantProps = {
                ...participant,
                IdCards: [
                    {
                        ...participant.IdCards[0],
                        IssuedDt: !participant.IdCards[0].IssuedDt
                            ? formatSimpleaDate(dayjs(values.issueDate).toDate())
                            : participant.IdCards[0].IssuedDt,
                        Issuer: values.issuer || '',
                    },
                ],
                PersonalIdentification: {
                    ...participant.PersonalIdentification,
                    BirthCountryCode: values.birthCountry ?? null,
                    BirthDt: participant.PersonalIdentification.BirthDt,
                    Birthplace: values.birthPlace || participant.PersonalIdentification.Birthplace,
                    CitizenshipCode: values.citizenship || '',
                    DeserializationCode: participant.PersonalIdentification.DeserializationCode,
                    TitleBefore: values.titleBefore || '',
                    TitleAfter: values.titleAfter || '',
                },
                BankAccounts: [
                    {
                        BankAccountNo: values.bankAccountNo || '',
                        BankCode: values.bankAccountCode || '',
                        Prefix: values.bankAccountPrefix || '',
                        Iban: '',
                    },
                ],
                Contact: {
                    Email: values.email || '',
                    Phone: values.phone || '',
                    PhonePrefix: {
                        ...participant.Contact.PhonePrefix,
                        Prefix: values.phonePrefix || '',
                    },
                },
                IsPep: isPep,
            };

            const body: ModelRequestBodyType = {
                ...restOfResponse,
                ClientPersonDtoJson: JSON.stringify(ClientPersonDtoJson),
            };

            axios
                .post(`${getBaseUrl()}/api/client-aml-identification/confirm-identification-data-by-bank-id`, body, {
                    timeout: 10000,
                })
                .then((response) => {
                    if (response.status === 200) {
                        setIsIdentificationSuccessful(true);
                    }
                })
                .catch((error: any) => {
                    console.error('err: ', error);
                });
        },
        [isPep, participant, response]
    );

    const handleValidate = useCallback(
        (values: BankIdConfirmationFormData) => {
            !submitted && setSubmitted(true);
            try {
                validateYupSchema(values, validationSchemaDefault(!!issueDate), true, values);
            } catch (err) {
                return yupToFormErrors(err);
            }
            return {};
        },
        [issueDate, submitted]
    );

    const prefixesList = initData?.InternationalPhonePrefixes.map((prefix) => prefix.PhonePrefix);

    const isSupportedPrefix = prefixesList?.includes(participant.Contact.PhonePrefix.Prefix);

    const phoneNumber =
        participant.Contact.Phone && participant.Contact.PhonePrefix
            ? `${participant.Contact.PhonePrefix.Prefix} ${formatPhoneNumber(participant.Contact.Phone || '')}`
            : '';

    const hasBirthPlace = !!participant.PersonalIdentification?.Birthplace;

    const hasIssuer = !!participant.IdCards[0].Issuer && participant.IdCards[0].Issuer.length > 0;

    // TODO: check and implement for this case

    // const idType =
    //   ctx.initData &&
    //   ctx.initData?.Lovs.IdCardTypes.find(
    //     (id) => id.Id === participant.Identification.IdCard?.Kind
    //   )?.NameTranslated;

    // const hasBirthCountry =
    //   participant.Identification.PersonalIdentificationDetail?.BirthCountry &&
    //   participant.Identification.PersonalIdentificationDetail?.BirthCountry
    //     .length > 0;

    // const birthCountry =
    //   ctx.initData &&
    //   ctx.initData?.Lovs.Countries.find(
    //     (country) =>
    //       country.Code ===
    //       participant.Identification.PersonalIdentificationDetail?.BirthCountry
    //   )?.NameTranslated;

    const hasCitizenship = !!participant.PersonalIdentification.CitizenshipCode;

    // const citizenship =
    //   ctx.initData &&
    //   ctx.initData?.Lovs.Countries.find(
    //     (country) =>
    //       country.Code ===
    //       participant.Identification.PersonalIdentificationDetail?.Citizenship
    //   )?.NameTranslated;

    // const hasSecondCitizenship =
    //   participant.Identification.PersonalIdentificationDetail
    //     ?.SecondCitizenship &&
    //   participant.Identification.PersonalIdentificationDetail?.SecondCitizenship
    //     .length > 0;

    // const secondCitizenship =
    //   ctx.initData &&
    //   ctx.initData?.Lovs.Countries.find(
    //     (country) =>
    //       country.Code ===
    //       participant.Identification.PersonalIdentificationDetail
    //         ?.SecondCitizenship
    //   )?.NameTranslated;

    return {
        isPep,
        setIsPep,
        onSubmit,
        participant,
        isSupportedPrefix,
        hasIssuer,
        submitted,
        setSubmitted,
        issueDate,
        hasIssueDate,
        hasCitizenship,
        initData,
        isSubmitting,
        lovs,
        phoneNumber,
        hasBirthPlace,
        handleValidate,
        isIdentificationSuccessful,
    };
};
