import React, { useMemo } from 'react';
import cn from 'classnames';

import { getDataTest } from '../../utils';
import { Field, FieldProps, getIn, useFormikContext } from 'formik';
import { TextInputProps } from '../Input/Input';
import { Error } from '../Error/Error';
import DateInput from './DateInput';

interface FormikDateInputProps {
    name: string;
    label?: string;
    isCenter?: boolean;
    field?: FieldProps['field'];
    className?: string;
    placeholder?: string;
    autoFocus?: boolean;
    disabled?: boolean;
    required?: boolean;
    // value?: string;
    excludeDates?: Date[];
    minDate?: Date;
    maxDate?: Date;
    fieldClassName?: string;
    // helpText?: string;
    // helpLocation?: 'label' | 'input';
    // withoutHelperText?: boolean;
    errorMessage?: string;
}

const FormikDateInput = ({
    name,
    label,
    className,
    placeholder,
    autoFocus,
    field,
    isCenter = true,
    disabled = false,
    required = false,
    fieldClassName,
    // helpText,
    // helpLocation = 'input',
    // withoutHelperText = false,
    errorMessage,
    minDate,
    maxDate,
    // size = 'medium',
    ...componentProps
}: FormikDateInputProps) => {
    const formikContext = useFormikContext();

    const inErrorMessage = useMemo(
        () => getIn(formikContext?.errors, name) || errorMessage,
        [name, formikContext.errors, errorMessage]
    );

    return (
        <div className={cn('textInputWrap', className)} {...componentProps}>
            {label && (
                <label
                    className={cn(
                        // 'mb-2 flex cursor-pointer flex-row text-sm text-darkPurple',
                        isCenter ? 'justify-center text-center' : 'pl-4',
                        disabled && 'pointer-events-auto cursor-auto text-gray'
                    )}
                    htmlFor={disabled ? undefined : field?.name}
                    data-test={getDataTest(componentProps, 'Label')}
                >
                    {label}
                    {required && <span data-test="symbolRequired"> *</span>}
                    {/* {helpText && helpLocation === 'label' && (
                        <Help text={helpText} data-test={getDataTest(componentProps, 'Help')} />
                    )} */}
                </label>
            )}

            <Field
                id={name}
                name={name}
                placeholder={placeholder}
                autoFocus={autoFocus}
                minDate={minDate}
                maxDate={maxDate}
                showEditIcon
                // validate={(value: string | number) =>
                //     (allowFieldValidation || allowFieldValidation === undefined) &&
                //     validateField(value, optional, min, max, variant)
                // }
            >
                {(props: JSX.IntrinsicAttributes & TextInputProps) => {
                    return (
                        <DateInput
                            {...props}
                            id={name}
                            key={name}
                            name={name}
                            isCenter={isCenter}
                            disabled={disabled}
                            className={fieldClassName}
                            errorMessage={inErrorMessage}
                            data-test={getDataTest(componentProps, 'Input')}
                        />
                    );
                }}
            </Field>

            {/* {helpText && helpLocation === 'input' && (
                <Help text={helpText} className="ml-4" data-test={getDataTest(componentProps, 'Help')} />
            )} */}

            <Error name={name} data-test={getDataTest(componentProps, 'Error')}>
                {inErrorMessage}
            </Error>

            {/* {!withoutHelperText && (
                <Error
                    name={field?.name}
                    className={cn('mt-2', isCenter ? 'text-center' : 'pl-4')}
                    data-test={getDataTest(componentProps, 'Error')}
                />
            )}*/}
        </div>
    );
};

export default FormikDateInput;
