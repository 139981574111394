import React, { Fragment, useContext, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { Formik, FormikProps } from 'formik';
import { Loader } from '@simplea/shared-ui';
import axios from 'axios';
import * as Yup from 'yup';
import { ROUTES } from '../../routes';
import { Layout } from '../../../components/Poland/Layout/Layout';
import usePolandParams from '../../../hooks/usePolandParams';
import { MojeIdContext } from '../../../context/MojeIdContext';
import PersonalDataForm, { PersonalDataFormData } from './PersonalDataForm';
import { getBaseUrl, subtractDate } from '../../../utils';
import { constants } from '../../../utils/constants';
import Button from './Button';

interface RequiredPersonalDataFormData extends Required<PersonalDataFormData> {}

const PersonalData = () => {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const myFormRef = useRef<FormikProps<any>>(null);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState<string | null>(null);
    const { mojeIdData, clientData } = useContext(MojeIdContext);
    const { onlinePolicyExternalId, participantExternalId, requestIdentifier } = usePolandParams(true);

    useEffect(() => {
        if (!mojeIdData) {
            if (onlinePolicyExternalId && participantExternalId) {
                navigate({
                    pathname: ROUTES.mojeidProcess,
                    search: `?onlinePolicyExternalId=${onlinePolicyExternalId}&participantExternalId=${participantExternalId}&requestIdentifier=${requestIdentifier}`,
                });
            }
        }
    }, [navigate, mojeIdData, onlinePolicyExternalId, participantExternalId, requestIdentifier]);

    // separe data z moje id
    const clientPersonalData = clientData && clientData?.PersonalIdentification;
    const clientPersonalAddress =
        clientData && clientData?.PermanentAddresses?.length > 0 ? clientData.PermanentAddresses[0] : null;
    const clientContactAddress =
        clientData && clientData?.ContactAddresses?.length > 0 ? clientData.ContactAddresses[0] : null;
    const clientBankAccount =
        clientData && clientData?.BankAccounts?.length > 0 && clientData.BankAccounts[0].Iban
            ? clientData.BankAccounts[0]
            : null;

    const initialValues: PersonalDataFormData = {
        name: clientPersonalData?.FirstName || null,
        surname: clientPersonalData?.LastName || null,
        identificatorType: clientPersonalData?.PersonalIdentifierType || null,
        identificatorNumber: clientPersonalData?.PersonalIdentifier || null,
        sex: clientPersonalData?.Sex || null,
        dateOfBirth: clientPersonalData?.BirthDt || null,
        stateOfBirth: clientPersonalData?.BirthCountryCode || null,
        cityOfBirth: clientPersonalData?.Birthplace || null,
        nationality: clientPersonalData?.CitizenshipCode || null,
        secondCitizenship: clientPersonalData?.SecondCitizenshipCode || null,
        // trvalé bydliště
        street: clientPersonalAddress?.StreetName || null,
        houseNumber: clientPersonalAddress?.HouseNo || null,
        city: clientPersonalAddress?.CityName || null,
        zipCode: clientPersonalAddress?.Zip || null,
        country: clientPersonalAddress?.CountryCode || null,
        // kontaktní adresa
        anotherContactAddress: myFormRef.current?.values?.anotherContactAddress ?? !!clientContactAddress,
        contactStreet: clientContactAddress?.StreetName || null,
        contactHouseNumber: clientContactAddress?.HouseNo || null,
        contactCity: clientContactAddress?.CityName || null,
        contactZipCode: clientContactAddress?.Zip || null,
        contactCountry: clientContactAddress?.CountryCode || null,
        // bankovní účet
        bankAccountNumber: clientBankAccount?.Iban?.replaceAll(' ', '') || null,
    };

    const hasAnotherAddress = myFormRef.current?.values?.anotherContactAddress === true;

    const validationSchema = Yup.object().shape({
        name: Yup.string().required().min(2),
        surname: Yup.string().required().min(2),
        identificatorType: Yup.string().required(),
        identificatorNumber: Yup.string().required(),
        sex: Yup.string().required(),
        dateOfBirth: Yup.date()
            .required()
            .min(subtractDate(constants.MAX_AGE, 'years'))
            .max(subtractDate(constants.MIN_AGE, 'years')),
        stateOfBirth: Yup.string().required(),
        cityOfBirth: Yup.string().required().min(2),
        nationality: Yup.string().required(),
        // adresa
        street: Yup.string().required().min(2),
        houseNumber: Yup.string().required(),
        city: Yup.string().required().min(2),
        zipCode: Yup.string()
            .required()
            .matches(new RegExp(constants.POLISH_POST_REGEX), t('general.errors.wrongPostalCode') || ''),
        country: Yup.string().required(),
        // kontaktní adresa
        contactStreet: hasAnotherAddress ? Yup.string().required().min(2) : Yup.string().nullable(),
        contactHouseNumber: hasAnotherAddress ? Yup.string().required() : Yup.string().nullable(),
        contactCity: hasAnotherAddress ? Yup.string().required().min(2) : Yup.string().nullable(),
        contactZipCode: hasAnotherAddress
            ? Yup.string()
                  .required()
                  .matches(new RegExp(constants.POLISH_POST_REGEX), t('general.errors.wrongPostalCode') || '')
            : Yup.string().nullable(),
        contactCountry: hasAnotherAddress ? Yup.string().required() : Yup.string().nullable(),
        // účet
        bankAccountNumber: Yup.string()
            .nullable()
            .matches(new RegExp(constants.POLISH_BANK_NUMBER), t('general.errors.wrongBankAccountNumber') || ''),
    });

    const onSubmit = async (values: PersonalDataFormData) => {
        if (clientData) {
            // omezení klientských dat před odesláním
            const submitClientData = {
                requestIdentificatorText: requestIdentifier,
                onlinePolicyExternalId: onlinePolicyExternalId,
                participantExternalId: participantExternalId,
                clientData: {
                    ...clientData,
                    BankAccounts: clientBankAccount?.Iban ? [clientBankAccount] : null,
                },
            };

            // uložení
            setError(null);
            setLoading(true);
            try {
                // validace na sankční seznamy
                const validateSactionResponse = await axios.post(
                    `${getBaseUrl()}/api/validations/validate-person-pl-sanction`,
                    { personalIdentificationFullJson: JSON.stringify(submitClientData.clientData) }
                );
                // validní
                if (validateSactionResponse.data.IsValid) {
                    // validace na pesel
                    const validatePeselResponse = await axios.post(
                        `${getBaseUrl()}/api/validations/validate-pesel-by-birthdate-and-gender`,
                        {
                            pesel: values.identificatorNumber ?? '',
                            birthDate: values.dateOfBirth ?? '',
                            sexKind: values.sex ?? '',
                        }
                    );

                    // kontrola, zda má klient jiné aktivní smlouvy
                    const validateAnyActivePolicy = await axios.post(
                        `${getBaseUrl()}/api/support/has-client-by-pesel-any-active-policy`,
                        { pesel: values.identificatorNumber }
                    );

                    // vyhodnocení validace na pesel
                    if (validateAnyActivePolicy.data?.HasAnyActivePolicy === true) {
                        setError('clientByPeselHasAnyActivePolicy');
                    } else if (
                        validatePeselResponse.data.IsPeselValidByCheckSum === false ||
                        validatePeselResponse.data.IsPeselValidByBirthDate === false ||
                        validatePeselResponse.data.IsPeselValidByGender === false
                    ) {
                        setError('isNotValidByBirthDatePesel');
                    } else {
                        // uložení
                        await axios.post(`${getBaseUrl()}/api/moje-id/confirm-client-data`, submitClientData);
                        // done
                        navigate({
                            pathname: ROUTES.mojeidFinished,
                            search: `?onlinePolicyExternalId=${onlinePolicyExternalId}&participantExternalId=${participantExternalId}&requestIdentifier=${requestIdentifier}`,
                        });
                    }
                } else {
                    setError('isFromSanctionRegion');
                }
            } catch (err) {
                console.error(err);
            } finally {
                setLoading(false);
            }
        }
    };

    if (!mojeIdData) {
        return (
            <Layout
                headline={t('personalData.title')}
                description={t('personalData.subtitle')}
                footerContent={
                    <Fragment>
                        <Button variant="primary" outlined disabled data-test="changeBankButton">
                            {t('personalData.changeBank')}
                        </Button>
                        <Button variant="primary" disabled data-test="confirmAndSendButton">
                            {t('personalData.confirmAndSend')}
                        </Button>
                    </Fragment>
                }
            >
                <Loader size="medium" className="mt-[30%]" />
            </Layout>
        );
    }

    return (
        <Formik<RequiredPersonalDataFormData>
            innerRef={myFormRef}
            enableReinitialize
            initialValues={initialValues}
            onSubmit={onSubmit}
            validateOnBlur={false}
            validateOnMount={false}
            validationSchema={validationSchema}
        >
            {({ values, setFieldValue, handleSubmit }: FormikProps<RequiredPersonalDataFormData>) => {
                return (
                    <PersonalDataForm
                        values={values}
                        error={error}
                        disabled={loading}
                        setFieldValue={setFieldValue}
                        onSubmit={handleSubmit}
                    />
                );
            }}
        </Formik>
    );
};

export default PersonalData;
