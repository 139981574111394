import { ChangeEvent } from 'react';
import { FieldProps } from 'formik';
import cn from 'classnames';
import Tooltip from '../Tooltip';
import { ReactComponent as Question } from '../../static/images/question.svg';
import { getDataTest } from '../../utils';
// import { FormError } from './FormError';
// import { Help } from './Help';

interface SwitchProps {
    field: FieldProps['field'];
    label: string;
    helpText?: string;
    error?: string;
    disabled?: boolean;
    isCenter?: boolean;
    className?: string;
    tooltip?: string;
    tooltipTitle?: string;
    onChange?: (e: ChangeEvent) => void;
}

export const Switch = ({
    field,
    label,
    helpText,
    error,
    className,
    disabled = false,
    isCenter = false,
    tooltip,
    tooltipTitle,
    onChange,
    ...props
}: SwitchProps) => {
    return (
        <div className={cn(className, 'relative', disabled && 'pointer-events-none text-gray')}>
            <div className={cn('flex items-center gap-x-2', isCenter && 'justify-center')}>
                <label className="switch" data-test={getDataTest(props, 'Label')}>
                    <input
                        {...field}
                        onChange={(e) => {
                            if (disabled) {
                                e.preventDefault();
                            } else {
                                onChange ? onChange(e) : field.onChange(e);
                            }
                        }}
                        className="input-hidden"
                        id={field.name}
                        type="checkbox"
                        checked={field.value}
                        data-test={getDataTest(props, 'Input')}
                    />
                    <span
                        className={cn(
                            'switch__mark',
                            !!error && '!border-redLight',
                            disabled && '!border-gray !bg-gray',
                            disabled && !field.value && '!bg-lightGray'
                        )}
                    />

                    {label && <>{label}</>}
                </label>

                {tooltip && (
                    <Tooltip title={tooltipTitle} content={tooltip} data-test={getDataTest(props, 'Tooltip')}>
                        <Question className="ml-5" />
                    </Tooltip>
                )}

                {/* {helpText && (
                    <Help
                        text={helpText}
                        data-test={(props as any)['data-test'] ? `${(props as any)['data-test']}HelperText` : undefined}
                    />
                )} */}
            </div>

            {/* <FormError
                className="mt-2"
                name={field.name}
                data-test={(props as any)['data-test'] ? `${(props as any)['data-test']}Error` : undefined}
            /> */}
        </div>
    );
};
