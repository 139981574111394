import React, { ReactNode } from 'react';

interface FooterProps {
    children?: ReactNode;
}

const Footer = ({ children }: FooterProps) => {
    return (
        <footer className="footer" data-test="footer">
            <div className="container">{children}</div>
        </footer>
    );
};

export default Footer;
