export const ROUTES = {
    notFound: '*',
    bankIdIntro: '/aml/bank-id',
    bankIdProcessing: '/bank-id',
    form: '/aml/formular',
    clientPortal: '/aml/klientsky-portal',
    // moje id
    mojeid: '/moje-id/*',
    mojeidProcess: '/moje-id/process',
    mojeidConfirm: '/moje-id/confirm-id',
    mojeidPersonalData: '/moje-id/personal-data',
    mojeidFinished: '/moje-id/finished',
    mojeIdOnline: '/moje-id/online/:smsCode',
    confirmed: '/moje-id/confirmed',
    // systém
    error: '/error',
};
