import React, { Fragment, ReactNode, useContext, useEffect } from 'react';
import axios from 'axios';
import { getBaseUrl, parseJson } from '../../../utils';
import { MojeIdContext } from '../../../context/MojeIdContext';

interface MojeIdWrapperProps {
    children: ReactNode;
}

const MojeIdWrapper = ({ children }: MojeIdWrapperProps) => {
    const { setInitData } = useContext(MojeIdContext);

    useEffect(() => {
        axios
            .post(
                `${getBaseUrl()}/api/client-aml-identification/get-bankId-identification-init-data`,
                {},
                {
                    timeout: 10000,
                }
            )
            .then((response) => {
                setInitData(parseJson(response.data?.BankIdIdentificationInitData));
            })
            .catch((err) => console.error(err));
    }, [setInitData]);

    return <Fragment>{children}</Fragment>;
};

export default MojeIdWrapper;
