import { Fragment } from 'react';
import { useTranslation } from 'react-i18next';
import { Button } from '@simplea/shared-ui';
import ghost from '../../static/images/ghost.png';

export const Invalid = () => {
    const { t } = useTranslation();

    return (
        <Fragment>
            <img src={ghost} alt="working" height={300} data-test="workingImage" />
            <Button onClick={() => window && window.open(t('marketingWebLink'), '_self')} data-test="redirectButton">
                {t('simpleaRedirect')}
            </Button>
        </Fragment>
    );
};
