import { ReactElement } from 'react';
import './Close.styles.css';

type Props = {
    onClick: () => void;
    className?: string;
};

export const Close = ({ onClick, className = '' }: Props): ReactElement | null => {
    return (
        <button onClick={onClick} className={`closeWrap ${className}`}>
            <div className="cross" />
        </button>
    );
};
