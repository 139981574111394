import React from 'react';
import { useTranslation } from 'react-i18next';
import PopupWrapper from '../../../components/Popup/PopupWrapper';
import zorbing from '../../../static/images/icon-zorbing-red.png';
import PopupButtons from '../../../components/Popup/PopupButtons';
import Button from './Button';

interface ChangeBankPopupProps {
    onSubmit: () => void;
    onClose: () => void;
}

const ChangeBankPopup = ({ onSubmit, onClose }: ChangeBankPopupProps) => {
    const { t } = useTranslation();

    const handleClose = () => {
        onClose();
    };

    return (
        <PopupWrapper data-test="changeBankPopup">
            <img src={zorbing} alt="" className="max-w-32" data-test="changeBankPopupImage" />
            <h3 className="m-0 mt-2.5" data-test="changeBankPopupTitle">
                {t('changeBankPopup.title')}
            </h3>
            <p className="text-lg" data-test="changeBankPopupDescription">
                {t('changeBankPopup.description')}
            </p>
            <PopupButtons>
                <Button
                    small
                    outlined
                    className="bg-white-force"
                    data-test="changeBankPopupStayHereButton"
                    onClick={handleClose}
                >
                    {t('changeBankPopup.stayHere')}
                </Button>
                <Button small data-test="changeBankPopupChangeBankButton" onClick={onSubmit}>
                    {t('changeBankPopup.changeBank')}
                </Button>
            </PopupButtons>
        </PopupWrapper>
    );
};

export default ChangeBankPopup;
