import { Field, FormikErrors } from 'formik';

import { BankIdConfirmationFormData, BankIdConfirmationFormFields } from '../useBankIdConfirmation';
import { FormikTextInput, InputType, Label, Select, SelectOptionProps, Error } from '@simplea/shared-ui';

type Props = {
    errors: FormikErrors<BankIdConfirmationFormData>;
    bankOptions: SelectOptionProps[];
};

export const BankAccount = ({ errors, bankOptions }: Props) => {
    return (
        <>
            <div className="confirmationItemCentered mobileMargin">
                <h3>Bankovní účet</h3>
            </div>
            <div className="bankAccount">
                <FormikTextInput
                    label="Předčíslí"
                    name={BankIdConfirmationFormFields.BankAccountPrefix}
                    variant={InputType.NUMBER}
                />
                <FormikTextInput
                    label="Číslo účtu"
                    name={BankIdConfirmationFormFields.BankAccountNo}
                    optional
                    variant={InputType.NUMBER}
                    error={errors.bankAccountNo}
                />
                <Label htmlFor={BankIdConfirmationFormFields.BankAccountCode} label="Banka">
                    <Field
                        id={BankIdConfirmationFormFields.BankAccountCode}
                        name={BankIdConfirmationFormFields.BankAccountCode}
                        component={Select}
                        options={bankOptions}
                        error={errors.bankAccountCode}
                    />
                    <Error isDisplayed={!!errors.bankAccountCode}>{errors.bankAccountCode}</Error>
                </Label>
            </div>
        </>
    );
};
