import React, { Fragment, ReactElement, SyntheticEvent } from 'react';
import { Link } from 'react-router-dom';
import cn from 'classnames';
import { Loader } from '@simplea/shared-ui';
import { getDataTest } from '../../../utils';
import './Button.styles.css';

type Variant = 'primary' | 'secondary' | 'tertiary' | 'link' | 'white';

export interface ButtonProps {
    children: ReactElement | string;
    variant?: Variant;
    submit?: boolean;
    small?: boolean;
    outlined?: boolean;
    href?: string;
    onClick?: (e?: SyntheticEvent) => void;
    className?: string;
    renderAsLabel?: boolean;
    htmlFor?: string;
    external?: boolean;
    disabled?: boolean;
    loading?: boolean;
    active?: boolean;
}

const btnVariant = (variant: Variant) => {
    if (!variant) return '';
    if (variant === 'white') return 'whiteBtn';
    return variant;
};

const Button = ({
    children,
    variant = 'primary',
    outlined,
    disabled,
    loading,
    small,
    href,
    submit,
    onClick,
    className,
    renderAsLabel,
    htmlFor,
    external,
    active,
    ...props
}: ButtonProps): ReactElement => {
    const classnames = cn(
        'wrapper',
        className,
        disabled && 'disabled',
        variant && btnVariant(variant),
        outlined && 'outlined',
        loading && 'loading',
        small && 'small',
        active && 'active'
    );

    const content = (
        <Fragment>
            <Loader
                className="loader"
                size={small ? 'xsmall' : 'small'}
                color="unset"
                data-test={getDataTest(props, 'Loading')}
            />
            <span className="content">{children}</span>
        </Fragment>
    );

    if (!onClick && !href) {
        href = '#';
    }

    if (renderAsLabel && htmlFor) {
        return (
            <label htmlFor={htmlFor} className={classnames} data-test={getDataTest(props, 'Label')}>
                {content}
            </label>
        );
    }

    if (submit) {
        return (
            <button type="submit" className={classnames} disabled={disabled} {...props}>
                {content}
            </button>
        );
    }

    if (onClick) {
        return (
            <button type="button" onClick={onClick} className={classnames} disabled={disabled} {...props}>
                {content}
            </button>
        );
    }

    if (external && href) {
        return (
            <a href={href} className={classnames} {...props}>
                {content}
            </a>
        );
    }

    return (
        <Link to={href ?? '/'} className={classnames} {...props}>
            {content}
        </Link>
    );
};

export default Button;
