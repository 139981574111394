import React, { useContext, useEffect } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import axios from 'axios';
import { Loader } from '@simplea/shared-ui';
import { Layout } from '../../../components/Poland';
import usePolandParams from '../../../hooks/usePolandParams';
import { getBaseUrl } from '../../../utils';
import { ROUTES } from '../../routes';
import { MojeIdContext } from '../../../context/MojeIdContext';

const Process = () => {
    const navigate = useNavigate();
    const [searchParams] = useSearchParams();
    const { setMojeIdData, setMojeIdValidate } = useContext(MojeIdContext);
    const { onlinePolicyExternalId, participantExternalId, requestIdentifier } = usePolandParams();

    const success = searchParams.get('success');

    useEffect(() => {
        // vlastní vyhodnocení
        if (!(success || (onlinePolicyExternalId && participantExternalId && requestIdentifier))) {
            navigate('/moje-id/not-found');
        }
    }, [navigate, success, onlinePolicyExternalId, participantExternalId, requestIdentifier]);

    useEffect(() => {
        axios({
            method: 'post',
            url: `${getBaseUrl()}/api/moje-id/get-persisted-moje-id-data`,
            data: {
                onlinePolicyExternalId,
                participantExternalId,
                requestIdentificatorText: requestIdentifier,
            },
        })
            .then(async (response) => {
                const item = (response.data.Items || []).find((i: any) => i.ReceivedClientPersonDtoJson);
                if (item) {
                    // uložení dat
                    setMojeIdData(JSON.parse(item.ReceivedClientPersonDtoJson));

                    // validace dat z mojeid
                    const validationResponse = await axios({
                        method: 'post',
                        url: `${getBaseUrl()}/api/moje-id/validate-moje-id-data`,
                        data: {
                            onlinePolicyExternalId,
                            participantExternalId,
                            clientDataJson: item.ReceivedClientPersonDtoJson,
                        },
                    });
                    setMojeIdValidate(validationResponse.data || null);

                    // success
                    navigate({
                        pathname: ROUTES.mojeidConfirm,
                        search: `?onlinePolicyExternalId=${onlinePolicyExternalId}&participantExternalId=${participantExternalId}&requestIdentifier=${requestIdentifier}`,
                    });
                } else {
                    navigate(
                        `/moje-id/?onlinePolicyExternalId=${onlinePolicyExternalId}&participantExternalId=${participantExternalId}&requestIdentifier=${requestIdentifier}`
                    );
                }
            })
            .catch(() => {
                navigate('/moje-id/not-found');
            });
    }, [navigate, setMojeIdData, setMojeIdValidate, onlinePolicyExternalId, participantExternalId, requestIdentifier]);

    return (
        <Layout hideFooter>
            <Loader size="medium" className="mt-[50%]" />
        </Layout>
    );
};

export default Process;
