import { getDataTest } from '../../utils';
import './List.styles.css';

type Props = {
    items: string[];
};

export const List = ({ items, ...props }: Props) => {
    return (
        <ol className="listWrap" {...props}>
            {items?.map((item, index) => (
                <li
                    key={item}
                    className="listItem"
                    data-test={getDataTest(props, `Item${index}`)}
                    dangerouslySetInnerHTML={{
                        __html: item,
                    }}
                />
            ))}
        </ol>
    );
};
