import { Button } from '@simplea/shared-ui';
import { Box } from '../../../../components';

export const Success = () => {
    return (
        <div className="successWrap">
            <Box>
                <div className="headline">Děkujeme za dokončení vaší identifikace</div>
                <p className="description">
                    Identifikace přes BankID byla dokončena. Od nás je to vše, pokud bychom přece jenom od vás
                    potřebovali něco doplnit, budeme vás kontaktovat.
                </p>
                <Button variant="secondary" onClick={() => window && window.open('https://www.simplea.cz', '_self')}>
                    Přejít na web Simplea.cz
                </Button>
            </Box>
        </div>
    );
};
