import { LoaderProps as UiLoaderProps, Loader as UiLoader } from '@simplea/shared-ui';
import cn from 'classnames';

interface LoaderProps extends UiLoaderProps {
    overflow?: boolean;
}

const LoaderContent = ({ className }: LoaderProps) => (
    <UiLoader size="medium" className={cn('mt-0-force', className)} />
);

const Loader = ({ overflow = false, ...props }: LoaderProps) => {
    if (overflow) {
        return (
            <div
                className={cn(
                    'loader-overflow',
                    'fixed top-0 left-0 bottom-0 right-0 z-60 flex bg-white opacity-70 justify-center items-center'
                )}
            >
                <LoaderContent {...props} />
            </div>
        );
    }

    return <LoaderContent {...props} />;
};

export default Loader;
