import { ReactElement } from 'react';
import cn from 'classnames';

import { ReactComponent as Checked } from '../../static/images/icons/checked.svg';
import './Checkbox.styles.css';

export type CheckBoxProps = {
    checked: boolean;
    size?: 'small' | 'large';
    onClick?: () => void;
    className?: string;
};

export const CheckBox = ({ checked, className, onClick }: CheckBoxProps): ReactElement => (
    <div onClick={onClick ? onClick : undefined} className={cn('button', className, checked && 'isActive')}>
        <Checked className={`${checked && 'checkIconVisible'} checkIcon`} />
    </div>
);
