import { useNavigate } from 'react-router-dom';
import { ROUTES } from '../../routes';
import { constants } from '../../../utils/constants';
import { Invalid } from '../../Invalid/Invalid';
import { Button } from '@simplea/shared-ui';
import { IdentificationError } from './Processing';
import { bankIdErrorMapper } from './utils';
import iconWorking from '../../../static/images/icon-workman.png';

type Props = {
    stateText: string | null;
    error: IdentificationError;
};

export const ProcessingErrors = ({ stateText, error }: Props) => {
    const isCustomError = error.data.length > 0;
    const navigate = useNavigate();
    if (isCustomError)
        return (
            <div className={`bankIdErrorWrap ${error.isBankIdError ? '' : 'gap'}`}>
                {error.isBankIdError ? (
                    <img src={iconWorking} alt="working" className="bankIdErrorImg" />
                ) : (
                    <div className="errorBox">
                        {error.data?.map((err) => {
                            //  @ts-ignore
                            const customError = bankIdErrorMapper[err.ErrorCode];
                            return customError && <div key={err?.ErrorCode}>{customError}</div>;
                        })}
                    </div>
                )}
                <Button
                    onClick={() =>
                        navigate({
                            pathname: ROUTES.bankIdIntro,
                            search: `?${constants.PROCESS_ID_QUERY_PARAM}=${stateText}`,
                        })
                    }
                >
                    Použít jiné BankID
                </Button>
            </div>
        );
    return <Invalid />;
};
