import { ReactNode } from 'react';
import './DataPlaceholder.styles.css';
import { Label } from '../Label/Label';

type Props = {
    label: string;
    children: ReactNode;
    fullWidth?: boolean;
    className?: string;
};

export const DataPlaceholder = ({ label, children, fullWidth = false, className }: Props) => (
    <Label label={label} className={className}>
        <div className={`dataValue ${fullWidth ? '' : 'maxWidth'}`}>{children}</div>
    </Label>
);
