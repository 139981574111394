import { TFunction } from 'i18next';

export const errorLayoutProps = (
    isInvalid: boolean,
    t: TFunction<'translation', undefined>,
    isCustomError = false,
    isBankIdError = false
) => {
    if (isCustomError) {
        return {
            headline: 'Aj, vypadá to, že něco je špatně',
            description: !isBankIdError
                ? 'Některé údaje, které o vás vedeme v našem systému, neodpovídají údajům z BankId. Proces BankId můžete případně zopakovat kliknutím na tlačítko níže.'
                : '',
        };
    } else {
        return {
            headline: isInvalid ? t('errorPage.headline') : '',
            description: isInvalid ? t('errorPage.description') : '',
        };
    }
};
export const bankIdErrorMapper = {
    err_bankId_birth_no_is_different:
        'Identifikátor z BankId neodpovídá identifikátoru uloženému v našem systému (identifikátorem se rozumí např. rodné číslo)',
    err_bankId_last_name_is_different: 'Příjmení z BankId neodpovídá příjmení uloženému v našem systému.',
    err_bankId_birth_date_is_different: 'Datum narození z BankId neodpovídá datu narození uloženému v našem systému.',
};
