import React from 'react';
import { useTranslation } from 'react-i18next';
import { ReactComponent as EmailIcon } from '../../static/images/icons/mail.svg';
import { Logo } from './Logo';

interface FooterProps {
    isBankId: boolean;
}

const Footer = ({ isBankId }: FooterProps) => {
    const { t } = useTranslation();

    return (
        <footer className="footer">
            <div className="container">
                <div className="w-full flex space-between">
                    <Logo isBankId={isBankId} />
                    <div className="emailWrap">
                        <div className="emailIcon">
                            <EmailIcon />
                        </div>
                        <a className="link" href={`mailto:${t('email')}`}>
                            {t('email')}
                        </a>
                    </div>
                </div>
            </div>
        </footer>
    );
};

export default Footer;
