import React from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { Layout as LayoutPoland } from '../../components/Poland';
import brokenCycle from '../../static/images/broken-cycle.png';
import './Error400.styles.css';

const Error400 = () => {
    const { t } = useTranslation();

    return (
        <LayoutPoland hideFooter>
            <div className="error400">
                <div className="image">
                    <img src={brokenCycle} alt="" />
                </div>
                <div className="content">
                    <h2>{t('error400.title')}</h2>
                    <h4>
                        <Trans>error400.subtitle</Trans>
                    </h4>
                </div>
            </div>
        </LayoutPoland>
    );
};

export default Error400;
