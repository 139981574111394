import React from 'react';
import { useTranslation } from 'react-i18next';

const RequiredInputsInfo = () => {
    const { t } = useTranslation();

    return (
        <small className="absolute left-4 top-4" data-test="requiredInputsInfo">
            * {t('general.markedInputsAreMandatory')}
        </small>
    );
};

export default RequiredInputsInfo;
