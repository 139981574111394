import React, { Fragment, useContext, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { Formik, FormikProps } from 'formik';
import { Loader } from '@simplea/shared-ui';
import * as Yup from 'yup';
import { Layout } from '../../../components/Poland/Layout/Layout';
import usePolandParams from '../../../hooks/usePolandParams';
import { MojeIdContext } from '../../../context/MojeIdContext';
import ConfirmIdForm, { RequiredConfirmIdData } from './ConfirmIdForm';
import { ROUTES } from '../../routes';
import { addDate, nowDate, subtractDate } from '../../../utils';
import { constants } from '../../../utils/constants';
import Button from './Button';

const ConfirmId = () => {
    const { t } = useTranslation();
    const now = nowDate();
    const navigate = useNavigate();
    const { mojeIdData, clientData } = useContext(MojeIdContext);
    const { onlinePolicyExternalId, participantExternalId, requestIdentifier } = usePolandParams(true);

    useEffect(() => {
        if (!mojeIdData) {
            if (onlinePolicyExternalId && participantExternalId) {
                navigate({
                    pathname: ROUTES.mojeidProcess,
                    search: `?onlinePolicyExternalId=${onlinePolicyExternalId}&participantExternalId=${participantExternalId}&requestIdentifier=${requestIdentifier}`,
                });
            }
        }
    }, [navigate, mojeIdData, onlinePolicyExternalId, participantExternalId, requestIdentifier]);

    const clientIdCard = clientData && (clientData?.IdCards || []).length > 0 ? clientData.IdCards[0] : null;

    const initialValues: any = {
        documentType: clientIdCard?.Kind,
        idNumber: clientIdCard?.IdCardNo,
        issueDate: clientIdCard?.IssuedDt,
        expirationDate: clientIdCard?.ExpiryDt,
        issuedBy: clientIdCard?.Issuer,
    };

    const validationSchema = Yup.object().shape({
        documentType: Yup.string().required(),
        idNumber: Yup.string().required().min(2),
        issueDate: Yup.date().required().max(now).min(subtractDate(constants.MAX_DOCUMENT_AGE, 'years')),
        expirationDate: Yup.date().required().min(now).max(addDate(constants.MAX_DOCUMENT_AGE, 'years')),
        issuedBy: Yup.string().required().min(2),
    });

    const onSubmit = () => {
        navigate({
            pathname: ROUTES.mojeidPersonalData,
            search: `?onlinePolicyExternalId=${onlinePolicyExternalId}&participantExternalId=${participantExternalId}&requestIdentifier=${requestIdentifier}`,
        });
    };

    if (!mojeIdData) {
        return (
            <Layout
                headline={t('confirmId.title')}
                description={t('confirmId.subtitle')}
                footerContent={
                    <Fragment>
                        <Button variant="primary" disabled outlined data-test="changeBankButton">
                            {t('confirmId.changeBank')}
                        </Button>
                        <Button variant="primary" disabled data-test="confirmValuesButton">
                            {t('confirmId.confirmValues')}
                        </Button>
                    </Fragment>
                }
            >
                <Loader size="medium" className="mt-[30%]" />
            </Layout>
        );
    }

    return (
        <Formik<RequiredConfirmIdData>
            enableReinitialize
            initialValues={initialValues}
            onSubmit={onSubmit}
            validateOnBlur={false}
            validateOnMount={false}
            validationSchema={validationSchema}
        >
            {(props: FormikProps<RequiredConfirmIdData>) => <ConfirmIdForm {...props} />}
        </Formik>
    );
};

export default ConfirmId;
