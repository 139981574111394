import { Button } from '@simplea/shared-ui';
import React from 'react';
import { Trans } from 'react-i18next';

interface BackButtonProps {
    onClick: () => void;
}

const BackButton = ({ onClick }: BackButtonProps) => {
    return (
        <Button outlined small variant="tertiary" className="smaller" onClick={onClick}>
            <Trans>general.back</Trans>
        </Button>
    );
};

export default BackButton;
