import { useRef, useState } from 'react';
import cn from 'classnames';
import './Tooltip.styles.css';
import { TooltipProps } from './Tooltip';

const DesktopTooltip = ({ content, width = 'small', className, children }: TooltipProps) => {
    const ref = useRef<HTMLDivElement>(null);
    const [positionX, setPositionX] = useState('center');
    const [positionY, setPositionY] = useState('top');
    const [contentVisible, setContentVisible] = useState(false);

    const onMouseEnter = () => {
        const refPosition = ref.current?.getBoundingClientRect();
        // x pozice
        setPositionX(refPosition && refPosition.right + 150 > window.innerWidth ? 'right' : 'center');
        // y pozice
        setPositionY(refPosition && refPosition.top - 300 < 0 ? 'bottom' : 'top');
        // zobrazení
        setContentVisible(true);
    };

    const onMouseLeave = () => setContentVisible(false);

    return (
        <div
            ref={ref}
            className={cn(className, 'tooltip')}
            onClick={(e) => {
                e.preventDefault();
                e.stopPropagation();
            }}
            onMouseEnter={onMouseEnter}
            onMouseLeave={onMouseLeave}
        >
            {children}
            <div
                className={cn(
                    'tooltipWrapper',
                    contentVisible && 'block-force',
                    positionX === 'left' ? 'left-0' : positionX === 'right' ? 'right-0' : 'left-1/2 -translate-x-1/2'
                )}
                data-test="desktopTooltipWrapper"
            >
                {positionY !== 'top' && <div className="spacing" />}
                <div className="tooltipContent" data-test="desktopTooltipContent">
                    {content}
                </div>
                {positionY === 'top' && <div className="spacing" />}
            </div>
        </div>
    );
};

export default DesktopTooltip;
