import { errorClassName } from '../../../components/Error/Error';
import { formatPhoneNumber } from '../../../utils';
import { IdCardTypes } from '../../../utils/constants';
import { ParticipantProps } from '../../../utils/types';

type StateValidation = {
    [key in PrefixesWithOwnRegexType]: string;
};

enum PrefixesWithOwnRegexType {
    CZECH = '+420',
    SLOVAK = '+421',
    POLAND = '+48',
}

export const regex = {
    CZECH_CELLPHONE_REGEX: '^(6|7)\\d{8}$',
    SLOVAK_CELLPHONE_REGEX: '^(9)\\d{8}$',
    POLAND_CELLPHONE_REGEX: '^[1-9]\\d{8}$',
    OTHER_CELLPHONE_REGEX: '^\\d{8,12}$',
    EMAIL_REGEX:
        '^(?:[a-zA-Z0-9!#$%&\'*+/=?^_`{|}~-]+(?:\\.[a-zA-Z0-9!#$%&\'*+/=?^_`{|}~-]+)*|"(?:[\\x01-\\x08\\x0b\\x0c\\x0e-\\x1f\\x21\\x23-\\x5b\\x5d-\\x7f]|\\\\[\\x01-\\x09\\x0b\\x0c\\x0e-\\x7f])*")@(?:(?:[a-zA-Z0-9](?:[a-zA-Z0-9-]*[a-zA-Z0-9])?\\.)+[a-zA-Z0-9](?:[a-zA-Z0-9-]*[a-zA-Z0-9])?|\\[(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?|[a-zA-Z0-9-]*[a-zA-Z0-9]:(?:[\\x01-\\x08\\x0b\\x0c\\x0e-\\x1f\\x21-\\x5a\\x53-\\x7f]|\\\\[\\x01-\\x09\\x0b\\x0c\\x0e-\\x7f])+)\\])$',
};

const prefixesWithRegex: (PrefixesWithOwnRegexType | string)[] = Object.values(PrefixesWithOwnRegexType).map(
    (value) => value
);

export const participantInitialValues = (participant: ParticipantProps, isSupportedPrefix: boolean | undefined) => {
    return {
        titleBefore: participant.PersonalIdentification?.TitleBefore ?? '',
        titleAfter: participant.PersonalIdentification?.TitleAfter ?? '',
        birthPlace: participant.PersonalIdentification?.Birthplace ?? '',
        birthCountry: participant.PersonalIdentification?.BirthCountryCode ?? null,
        citizenship: participant.PersonalIdentification?.CitizenshipCode ?? null,
        secondCitizenship: participant.PersonalIdentification?.SecondCitizenshipCode ?? null,
        // ASK: really the first card?
        issuer: participant.IdCards[0]?.Issuer ?? '',
        validateSecondCitizenship: !!participant.PersonalIdentification?.SecondCitizenshipCode ?? false,
        bankAccountPrefix: participant.BankAccounts[0]?.Prefix ?? '',
        bankAccountNo: participant.BankAccounts[0]?.BankAccountNo ?? '',
        bankAccountCode: participant.BankAccounts[0]?.BankCode ?? null,
        email: validateEmail(participant.Contact.Email),
        phone: validatePhone(
            participant.Contact.Phone && isSupportedPrefix ? formatPhoneNumber(participant.Contact.Phone) : '',
            participant.Contact.PhonePrefix.Prefix
        ),
        phonePrefix: isSupportedPrefix ? participant.Contact.PhonePrefix.Prefix : '',
    };
};

const validationPerState: StateValidation = {
    [PrefixesWithOwnRegexType.CZECH]: regex.CZECH_CELLPHONE_REGEX,
    [PrefixesWithOwnRegexType.SLOVAK]: regex.SLOVAK_CELLPHONE_REGEX,
    [PrefixesWithOwnRegexType.POLAND]: regex.POLAND_CELLPHONE_REGEX,
};

const validateEmail = (email: string | null) => (!email || !new RegExp(regex.EMAIL_REGEX).test(email) ? '' : email);

const validatePhone = (phone: string | null, prefix: string) => {
    if (!phone || !prefix) return '';

    const formattedPhone = phone.replaceAll(' ', '');

    const validation = prefixesWithRegex.includes(prefix)
        ? validationPerState[prefix as PrefixesWithOwnRegexType]
        : regex.OTHER_CELLPHONE_REGEX;

    if (!formattedPhone || !new RegExp(validation).test(formattedPhone)) return '';

    return formattedPhone;
};

export const scrollToError = () => {
    setTimeout(() => {
        const error = document.querySelector(`.${errorClassName}`);
        error &&
            window &&
            window.scrollTo({
                top: error.getBoundingClientRect().y + window.scrollY - 100,
                left: 0,
                behavior: 'smooth',
            });
    }, 250);
};

export const getCardType = (kind: number | null) => IdCardTypes.find((idType) => idType.Id === kind)?.Name;
