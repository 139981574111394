import React from 'react';
import { useTranslation } from 'react-i18next';
import PopupWrapper from '../../../components/Popup/PopupWrapper';
import PopupButtons from '../../../components/Popup/PopupButtons';
import Glasses from '../../../static/images/glasses.png';
import Button from './Button';

interface SmsSendedPopupProps {
    onClose: () => void;
}

const SmsSendedPopup = ({ onClose }: SmsSendedPopupProps) => {
    const { t } = useTranslation();

    return (
        <PopupWrapper color="yellow" size="sm" data-test="smsSendedPopup">
            <img src={Glasses} alt="" className="mx-auto h-auto w-[8.125rem]" data-test="popupImage" />

            <p className="mb-8 text-center font-medium" data-test="popupDescription">
                {t('general.smsWithLinkWasSended')}
            </p>

            <PopupButtons align="center" data-test="popupButtons">
                <Button small outlined onClick={onClose} data-test="understandButton">
                    {t('general.understand')}
                </Button>
            </PopupButtons>
        </PopupWrapper>
    );
};

export default SmsSendedPopup;
