import { Loader } from '@simplea/shared-ui';
import React, { useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import axios from 'axios';
import { Layout } from '../../../components/Poland';
import { ROUTES } from '../../routes';
import { getBaseUrl } from '../../../utils';

interface MojeIdOnlinePolicyMojeIdMetadataByCodeResponse {
    OnlinePolicyExternalId: string;
    ParticipantExternalId: string;
}

const MojeIdOnline = () => {
    const { smsCode } = useParams();
    const navigate = useNavigate();

    useEffect(() => {
        if (!smsCode) {
            navigate(ROUTES.error);
        }
        if (smsCode) {
            axios
                .get<MojeIdOnlinePolicyMojeIdMetadataByCodeResponse>(
                    `${getBaseUrl()}/api/moje-id/get-online-policy-moje-id-metadata-by-code/${smsCode}`
                )
                .then((response) => response.data)
                .then((result) => {
                    if (result.OnlinePolicyExternalId && result.ParticipantExternalId) {
                        navigate({
                            pathname: '/moje-id/',
                            search: `?onlinePolicyExternalId=${result.OnlinePolicyExternalId}&participantExternalId=${result.ParticipantExternalId}&verificationCode=${smsCode}`,
                        });
                    } else {
                        navigate(ROUTES.error);
                    }
                })
                .catch(() => navigate(ROUTES.error));
        }
    }, [smsCode, navigate]);

    return (
        <Layout>
            <Loader size="medium" className="mt-[40%]" />
        </Layout>
    );
};

export default MojeIdOnline;
