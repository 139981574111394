import React, { useEffect } from 'react';
import { Route, Routes, matchPath, useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import * as Yup from 'yup';
import dayjs from 'dayjs';
import Welcome from './components/Welcome';
import Process from './components/Process';
import ConfirmId from './components/ConfirmId';
import PersonalData from './components/PersonalData';
import Finished from './components/Finished';
import Confirmed from './components/Confirmed';
import { NotFound } from '../Errors/NotFound';
import { Context } from '../../context/MojeIdContext';
import MojeIdWrapper from './components/MojeIdWrapper';
import MojeIdOnline from './components/Online';
import { constants } from '../../utils/constants';
import { ROUTES } from '../routes';

interface MojeIdProps {
    language: string;
}

const MojeId = ({ language }: MojeIdProps) => {
    const { t } = useTranslation();
    const location = useLocation();

    Yup.setLocale({
        mixed: {
            required: t('general.valueRequired'),
        },
        string: {
            email: t('general.errors.wrongEmailFormat') || '',
            min: ({ min }) => t('general.errors.minLenghtField', { length: min }) || '',
        },
        date: {
            min: ({ min }) =>
                t('general.errors.minDateTo', { date: dayjs(min).format(constants.HUMAN_DATE_FORMAT) }) || '',
            max: ({ max }) =>
                t('general.errors.maxDateTo', { date: dayjs(max).format(constants.HUMAN_DATE_FORMAT) }) || '',
        },
    });

    const isNextsLocation = (pathName?: string): boolean => {
        return (
            !pathName || !!(matchPath(ROUTES.mojeidConfirm, pathName) || matchPath(ROUTES.mojeidPersonalData, pathName))
        );
    };

    useEffect(() => {
        const onUnload = (e: any) => {
            e.preventDefault();
            return (e.returnValue = 'Are you sure you want to close?');
        };

        if (isNextsLocation(location.pathname)) {
            window.addEventListener('beforeunload', onUnload);
        }

        return () => window.removeEventListener('beforeunload', onUnload);
    }, [location.pathname]);

    return (
        <Context>
            <MojeIdWrapper>
                <Routes>
                    <Route path="finished" element={<Finished />} />
                    <Route path="confirmed" element={<Confirmed />} />
                    <Route path="process" element={<Process />} />
                    <Route path="confirm-id" element={<ConfirmId />} />
                    <Route path="personal-data" element={<PersonalData />} />
                    <Route path="online/:smsCode" element={<MojeIdOnline />} />
                    <Route path="" element={<Welcome />} />
                    <Route path="*" element={<NotFound language={language} />} />
                </Routes>
            </MojeIdWrapper>
        </Context>
    );
};

export default MojeId;
